import { combineReducers } from 'redux';

import { app } from './app.reducer'
import { project } from './project.reducer'
import { sensor } from './sensor.reducer'
import { auth } from './auth.reducer'
import { concreteStructure } from './concreteStructure.reducer'

const rootReducer = combineReducers({
  app,
  project,
  sensor,
  concreteStructure,
  auth
});

export default rootReducer;
