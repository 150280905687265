import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Link as RouterLink} from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import WorkIcon from '@material-ui/icons/Work';
import HomeIcon from '@material-ui/icons/Home';
import AdbIcon from '@material-ui/icons/Adb';
import {
  ShoppingBag as ShoppingBagIcon,
} from 'react-feather';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme: Theme) => createStyles({
  link: {
    display: 'flex',
  },
  root: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1)
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export default function IconBreadcrumbs(props: any) {
  const classes = useStyles();
  const { path } = props;

  function getIcon(index: number) {
    switch (index) {
      case 0:
        return <WorkIcon className={classes.icon} />
      case 1:
        return <HomeIcon className={classes.icon} />
      default:
        return <AdbIcon className={classes.icon} />
    }
  }

  function getTitle(index: number, title: string) {
    const category = ['Project', 'Location', 'Sensor']
    return `${category[index]}: ${title || ''} `
  }

  return (
      <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.root}
          separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
            to={`/app/projects`}
            component={RouterLink}
            className={classes.link}
            color={"textSecondary"}
        >
          <ShoppingBagIcon className={classes.icon} />
          Projects
        </Link>
        {
          path.map((navDestination: any, index: number) => {
            return (
                <Link
                    component={RouterLink}
                    color={"textSecondary"}
                    to={navDestination.link}
                    className={classes.link}
                >
                  {getIcon(index)}
                  {getTitle(index, navDestination.title)}
                </Link>
            )
          })
        }
      </Breadcrumbs>
  );
}
