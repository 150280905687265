import { sensorService } from 'src/services'
import {
  extractValueFromObj,
  formatDateTime,
  formatDateTimeFromSecondUnixTimeOrDate,
  percentageOrCustomValue,
  toFixOrCustomValue
} from '../../../utils'
import cst from "../../../constants";

// Transformed concrete structure to the values displayed in concrete structure overview
export const getConcreteStructureOverview = (concreteStructure, forSensorList = false) => {
  const castingDate = extractValueFromObj(concreteStructure, 'castingDate');
  const daySinceCasting = castingDate !== cst.NA_VALUE
    ? Math.floor((new Date() - new Date(castingDate).getTime()) / 24 / 3600 / 1000) : cst.NA_VALUE;
  const concreteGrade = extractValueFromObj(concreteStructure, 'cementType.property.concreteGrade'.split('.'))
  const aggregateSensorData = sensorService.aggregateProperty(
    concreteStructure.sensor.map(sensor => {
      // console.log(sensorService.aggregateProperty(sensor.property))
      const result = sensorService.aggregateProperty(sensor.property);
      if (sensor.current)
        result.strength = sensor.current.strength;
      return result;
    })
  );
  const { strength, maxTemp } = aggregateSensorData;
  const percentage = () => {
    try {
      const result = strength / concreteGrade * 100;
      if (result.toString() === 'NaN')
        return cst.NA_VALUE;
      return result.toFixed(2);
    } catch (e) {
      return cst.NA_VALUE;
    }
  }
  const result = [
    extractValueFromObj(concreteStructure, 'location'),
    formatDateTime(formatDateTimeFromSecondUnixTimeOrDate(castingDate, false, true)),
    daySinceCasting,
    toFixOrCustomValue(strength),
    percentageOrCustomValue(percentage()),
    toFixOrCustomValue(maxTemp),
    "",
    concreteStructure.cementType.name,
    toFixOrCustomValue(concreteGrade),
  ]
  if (forSensorList)
    result.splice(3,0, toFixOrCustomValue(concreteGrade))
  return result;
}
