import cst from '../constants';
import { userService } from '../services'

function updateUserInfo(id) {
  return async dispatch => {
    try {
      const userInfo = await userService.updateUserInfo(id);
      return dispatch({
        type: cst.ACTION_UPDATE_USER_INFO,
        payload: userInfo
      })
    } catch (error) {
      throw error;
    }
  }
}
export const userAction = {
  updateUserInfo
}
