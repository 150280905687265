import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';

const BTN_TEXT_CONFIRM = 'CONFIRM'
const Transition = React.forwardRef((props, ref) => {
  return <Grow direction="up" ref={ref} {...props} />;
});

export default function AlertDialog(props) {
  const {
    setDeleteConfirm, openDialog, setOpenDialog,
    content, header
  } = props;

  const handleClose = (e) => {
    if (e.target.innerText === BTN_TEXT_CONFIRM)
      setDeleteConfirm(true)
    else
      setDeleteConfirm(false)
    setOpenDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            {BTN_TEXT_CONFIRM}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
