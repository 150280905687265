import {formatDateTimeFromSecondUnixTimeOrDate, toFixOrCustomValue} from '../../../utils'

export const getSensorDataForTable = (sensor) => {
  // This is due to the change in the calculation formula
  const { strength, maxTemp } = sensor.current;

  const result = [
    sensor.device,
    toFixOrCustomValue(strength),
    toFixOrCustomValue(maxTemp),
    sensor.remark,
    sensor.lastReceivedAt ? formatDateTimeFromSecondUnixTimeOrDate(sensor.lastReceivedAt, false, true) : sensor.lastReceivedAt
  ]
  return result;
}

export const isActive = (currentSensor) => {
  return currentSensor.deactivatedAt < currentSensor.activatedAt
}

export const isTerminated = (sensor) => {
  return sensor.deactivatedAt > sensor.activatedAt
}
