import React, { useState } from 'react'
import ReactExport from 'react-export-excel';
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import {makeStyles} from "@material-ui/core/styles";
import { generateDatepointsIndex, getAvgTemp } from '../helper'
import { formatDateTime } from '../../../../utils'

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const formatDataset = (dataset, activeSensors) => {
  // This two variables are used to match to valid concrete strength

  return dataset.map((data, sensorIndex) => {
    let validCounter = 0
    const datapointIndexs = generateDatepointsIndex(activeSensors[sensorIndex])
    return data.allLabels.map((value, index) => {
      const result = {
        time: data.allLabels[index]
      }
      data.allTemperature.forEach((t, index1) => {
        result[`temp${datapointIndexs[index1]}`] = t[index]
      })

      if (data.temperatureIndex[validCounter] === index) {
        result.concreteStrength = data.concreteStrength[validCounter]
        result.avgTemp = getAvgTemp(data.temperature, index, false)
        validCounter += 1
      } else {
        // Outlier
        result.concreteStrength = 0
        result.avgTemp = 0
      }

      // TODO: handle moisture again
      if (data.labelsMoisture && data.labelsMoisture[index]) {
        result.timeMoisture = data.labelsMoisture[index]
        let avgMoisture = 0;
        data.moisture.forEach((t, index1) => {
          result[`moisture${datapointIndexs[index1]}`] = t[index]
          avgMoisture += t[index]
        })
        result.avgMoisture = avgMoisture / data.moisture.length
      }
      return result;
    })
  })
}

const useStyles = makeStyles((theme) => ({
  btn: {
    float: 'right',
    margin: '0 0 4px 8px'
  }
}))

const ExportSensorData = (props) => {
  const classes = useStyles();
  const { dataset, activeSensors, concreteStructure } = props;
  const [currentTime, setCurrentTime] = useState(new Date())

  const getFileName = () => {
    try {
      return `${concreteStructure.project.name}--${concreteStructure.location}--${formatDateTime(currentTime.toLocaleString())}`
    } catch (e) {
      return `${concreteStructure.location}--${formatDateTime(currentTime.toLocaleString())}`
    }
  }

  const deviceCounterForTemp = {}
  const deviceCounterForMoisture = {}
  const formattedDataset = formatDataset(dataset, activeSensors)

  return (
      <ExcelFile
          filename={getFileName()}
          element={(
              <Button
                  className={classes.btn}
                  variant="contained"
                  color="primary"
                  size={'medium'}
                  startIcon={<SaveIcon />}
                  onClick={() => setCurrentTime(new Date())}
              >
                Export Sensor Data
              </Button>
          )}
      >
        {formattedDataset.map((data, sensorIndex) => {
          const sensor = activeSensors[sensorIndex]
          deviceCounterForTemp[sensor.device] = !deviceCounterForTemp[sensor.device] ? 1 : deviceCounterForTemp[sensor.device] + 1
          const datapointIndexs = generateDatepointsIndex(sensor)
          return (
              <ExcelSheet
                  key={`${sensor.device}-${sensor.id}-Temperature`}
                  data={data}
                  name={`${sensor.device}-${deviceCounterForTemp[sensor.device] > 1 ? (`${deviceCounterForTemp[sensor.device] - 1}-`) : ''}Temperature`}
              >
                <ExcelColumn label="Time/Age (Days)" value="time"/>
                {dataset[sensorIndex].temperature.map((t, index) => (<ExcelColumn label={`Temp${datapointIndexs[index]}`} value={`temp${datapointIndexs[index]}`}/>))}
                <ExcelColumn label="Avg Temp" value="avgTemp"/>
                <ExcelColumn label="Concrete Strength" value="concreteStrength"/>
              </ExcelSheet>
          )
        })}
        {
          formattedDataset.map((data, index) => {
            const sensor = activeSensors[index]
            deviceCounterForMoisture[sensor.device] = !deviceCounterForMoisture[sensor.device]
                ? 1 : deviceCounterForMoisture[sensor.device] + 1
            return (
                dataset[index].labelsMoisture && (
                    <ExcelSheet
                        key={`${activeSensors[index].id}-Moisture`}
                        data={data}
                        name={`${sensor.device}-${deviceCounterForMoisture[sensor.device] > 1 ? (`${deviceCounterForMoisture[sensor.device] - 1}-`) : ''}Moisture`}
                    >
                      <ExcelColumn label="Time/Age (Days)" value="timeMoisture"/>
                      {dataset[index].moisture.map((t, index1) => (<ExcelColumn label={`Moisture${index1}`} value={`moisture${index1}`}/>))}
                      <ExcelColumn label="Avg Moisture" value="avgMoisture"/>
                    </ExcelSheet>
                )
            )
          })
        }
      </ExcelFile>
  );
}

const mapStateToProps = (state) => ({
  concreteStructure: state.concreteStructure
})

// export default connect(mapStateToProps)(ExportSensorData)
export default ExportSensorData
