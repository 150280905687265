import React, { useEffect, useState } from 'react'
import { Field, Form } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab'
import MuiTextField from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack'
import { ConcreteFields } from './ConcreteStructureSchema';
import { camelCaseToCapitalizeWord } from '../../../../utils/getInitials';
import { cementTypeService, concreteSupplierService } from '../../../../services'
import cst from '../../../../constants'
import { formError } from '../../../../utils'

const CustomTextField = (props) => {
  const textField = fieldToTextField(props);
  const { touched, errors } = props.form;
  const { name } = props.field;
  return (
    <MuiTextField
      inputProps={{
        step: 'any'
      }}
      error={touched[name] && errors[name]}
      helperText={touched[name] && !!errors[name]}
      fullWidth
      {...textField}
    />
  );
}

const AutoCompleteTextField = (props) => {
  const { touched, errors, params } = props;
  return (
    <MuiTextField
      {...params}
      error={touched['cementType'] && !!errors['cementType']}
      helperText={touched['cementType'] && errors['cementType']}
      label="Concrete Type"
    />
  )
}

const ConcreteSupplierName = (props) => {
  const { values } = props;
  return (
    values.concreteSupplier && values.concreteSupplier.name
      ? (
        <Grid item xs={6} >
          <Field
            component={CustomTextField}
            value={values.concreteSupplier.name}
            disabled
            name="concreteSupplierName"
            label="Concrete Supplier Name"
            type="string"
          />
        </Grid>
      ) : (
        <Grid item xs={6} >
          <Field
            component={CustomTextField}
            name="concreteSupplierName"
            label="Concrete Supplier Name"
            type="string"
          />
        </Grid>
      )
  )
}

const getHelperTextForProperty = (key) => {
  return ['concreteGrade', 'Q', 'RT', 'M0', 'K', 'Su', 'Offset'].includes(key)
    ? 'Number' : 'Kg/m3'
}
export function showCementChoice(values) {
  const temp = values && typeof values === 'object' ? values.concreteSupplier : values;
  return [cst.CREATE_VALUE, null, undefined]
    .findIndex(val => temp === val) === -1;
}

function isPropertyFieldEditable(values) {
  return values.concreteSupplier === cst.CREATE_VALUE || values.cementType === cst.CREATE_VALUE;
}

function showListOfCementProperty(values) {
  return (showCementChoice(values)
    && values.cementType !== undefined
    && values.cementType !== null)
    || isPropertyFieldEditable(values);
}

const ConcreteInformation = (props) => {
  const {
    touched, errors, values, setFieldValue, concreteSupplier
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [cementType, setCementType] = useState([]);
  const [keyCementType, setKey] = useState('first random key');

  useEffect(() => {
    if (!showCementChoice(values))
      return;
    (async () => {
      try {
        const response = await cementTypeService.getCementTypes({
          concreteSupplier: values.concreteSupplier.id
        });
        setCementType(response.results);
        setFieldValue('cementType', undefined)
      } catch (e) {
        console.log(e);
        enqueueSnackbar(formError(e), { variant: 'error' })
      }
    })();
  }, [values.concreteSupplier]);

  return (
    <Grid
      component={Form}
      container
      spacing={2}
    >
      <Grid item xs={6}>
        <Field
          name="concreteSupplier"
          component={Autocomplete}
          options={[cst.CREATE_VALUE].concat(concreteSupplier)}
          getOptionLabel={(option) => option.name || option}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              error={touched['concreteSupplier'] && !!errors['concreteSupplier']}
              helperText={touched['concreteSupplier'] && errors['concreteSupplier']}
              label="Concrete Supplier"
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        {showCementChoice(values) && (
          <Field
            name="cementType"
            component={Autocomplete}
            options={[cst.CREATE_VALUE].concat(cementType)}
            getOptionLabel={(option) => option.name || option}
            renderInput={(params) => {
              return (
                <AutoCompleteTextField
                  key={keyCementType}
                  touched={touched}
                  errors={errors}
                  params={params}
                />
              )
            }}
          />
        )}
      </Grid>
      {
        showListOfCementProperty(values)
        && (
          <>
            {isPropertyFieldEditable(values)
            && (
              <>
                <ConcreteSupplierName
                  values={values}
                />
                <Grid item xs={6} >
                  <Field
                    component={CustomTextField}
                    name="concreteTypeName"
                    label="Concrete Type Name"
                    type="string"
                  />
                </Grid>
              </>
            )}
            {
              (ConcreteFields.map(key => (
                <Grid item xs={6} key={key}>
                  {isPropertyFieldEditable(values)
                    ? (
                      <Field
                        component={CustomTextField}
                        name={`property.${key}`}
                        label={camelCaseToCapitalizeWord(key)}
                        type="number"
                        helperText={getHelperTextForProperty(key)}
                      />
                    ) : (
                      <MuiTextField
                        fullWidth
                        value={values.cementType.property[key]}
                        disabled
                        label={camelCaseToCapitalizeWord(key)}
                        type="number"
                        helperText={getHelperTextForProperty(key)}
                      />
                    )}
                </Grid>
              )))
            }
          </>
        )
      }
    </Grid>
  )
}

export default ConcreteInformation;
