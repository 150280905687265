import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import ConcreteStructureView from 'src/views/concreteStructure/ConcreteStructureListView';
import SensorView from 'src/views/sensor/SensorListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/project/ProjectListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import PrivateRoute from 'src/components/PrivateRoute';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      {
        path: 'projects/page/:page',
        element: <PrivateRoute component={ProductListView} />
      },
      {
        path: 'projects/:projectId',
        element: <PrivateRoute component={ConcreteStructureView} />
      },
      {
        path: 'concreteStructures/:concreteStructureId',
        element: <PrivateRoute component={SensorView} />
      },
      { path: 'account', element: <PrivateRoute component={AccountView}/> },
      { path: 'sensors', element: <PrivateRoute component={SensorView} /> },
      {
        path: 'dashboard/:concreteStructureId',
        element: <PrivateRoute component={DashboardView} />
      },
      { path: 'projects', element: <PrivateRoute component={ProductListView} /> },
      { path: 'settings', element: <PrivateRoute component={SettingsView} /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/projects" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
