import cst from 'src/constants';

export function formError(error, message = cst.ERROR_DEFAULT) {
  return !error ? message : typeof error === 'object' ? error.message : error;
}

export function getCurrentTime() {
  return Math.round((new Date()).getTime() / 1000) // - 48 * 3600;
}

export function toSecond(datetime) {
  return Math.round((new Date(datetime)).getTime() / 1000) // - 48 * 3600;
}

export const toUnixSecond = toSecond

export function getLastValue(arr, customValue = cst.NA_VALUE) {
  if (typeof arr === 'number')
    return arr;
  if (!arr || !arr.length)
    return customValue;
  return arr[arr.length - 1];
}

export function camelCaseToCapitalizeWord(text) {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function extractValueFromObj(obj, path, customValue = "NA") {
  if (path && typeof path === 'string')
    path = path.split('.')
  try {
    let result = obj;
    path.forEach(key => {
      result = result[key];
    })
    if (result === undefined)
      return customValue;
    return result;
  } catch (e) {
    return customValue;
  }
}

export const sum = (arr) => arr.reduce((a, b) => a + b, 0);
export const avg = (arr, customValue = "NA", shouldFilter = false) => {
  if (typeof arr === 'number')
    return arr;
  return !arr || !arr.length ? customValue : (sum(arr) / arr.length);
}

export const max = (arr, customValue = cst.NA_VALUE) => (!arr || !arr.length
  ? customValue : Math.max(...arr));
export const min = (arr, customValue = cst.NA_VALUE) => (!arr || !arr.length
  ? customValue : Math.min(...arr));

export const toFixOrCustomValue = (v, customValue = cst.NA_VALUE) => {
  if (v === null || v === undefined)
    return customValue
  return v === customValue ? customValue : v.toFixed(2)
}

export const percentageOrCustomValue = (v, customValue = cst.NA_VALUE) => {
  return v === customValue ? customValue : `${v}%`
}

export const pick = (obj, attrs = []) => Object.keys(obj)
  .filter(key => attrs.indexOf(key) >= 0)
  .reduce((obj2, key) => Object.assign(obj2, { [key]: obj[key] }), {});

export const convertTime = (datetime) => {
  const offset = -(new Date().getTimezoneOffset()) / 60;
  const newDate = new Date((new Date(datetime)).getTime() + offset * 3600 * 1000)
  return newDate.toString()
}

export function isAllZeroes(arr) {
  return !(arr.find(v => v !== 0) !== undefined)
}

export const debounce = (callback, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
}

export const isObjectEqual = (obj1, obj2) => {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
  }
  let result = true
  Object.keys(obj1).forEach(key => {
    if (obj1[key] !== obj2[key])
      result = false
  })
  return result
}

export const isValid = (value) => value !== undefined && value !== cst.NA_VALUE

export function formatDateTime(localeString) {
  const arr = localeString.split('/')
  const temp = arr[0]
  arr[0] = arr[1]
  arr[1] = temp
  return arr.join('/')
}

export function formatDateTimeFromSecondUnixTimeOrDate(datetime, includeSecond = true, hour12 = false) {
  const option = {
 year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
}
  if (includeSecond)
    option.second = 'numeric'
  if (hour12 === true)
    option.hour12 = true

  const localeString = typeof datetime === 'number' ? new Date(datetime * 1000).toLocaleString('en-US', option)
      : new Date(datetime).toLocaleString('en-US', option)
  const arr = localeString.split('/')
  const temp = arr[0]
  arr[0] = arr[1]
  arr[1] = temp
  return arr.join('/')
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}
