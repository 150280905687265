import { handleResponse } from './helper'
import { URL } from '../config'

async function createProject(projectBody) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(projectBody),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/projects`, requestOptions)
    .then(handleResponse);
}

const getProjects = async (query = {}) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(
    `${URL}/projects/?${new URLSearchParams(query)}`,
    requestOptions
  ).then(handleResponse);
};

const getProject = async (id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/project/${id}`, requestOptions)
    .then(handleResponse);
};

const deleteProject = async (id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/projects/${id}`, requestOptions)
    .then(handleResponse);
};

const resource = {
  createProject,
  getProjects,
  getProject,
  deleteProject
};

export default resource;
