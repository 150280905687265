import cst from '../constants';

function updatePageValue(payload) {
  return {
    type: cst.UPDATE_RECENT_PAGE_VALUE,
    payload
  }
}

export const appAction = {
  updatePageValue
};
