import React from 'react'
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useSnackbar } from 'notistack'
import { Formik } from 'formik';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress'

import ConcreteStructureSchema from './ConcreteStructureSchema';
import StructureInformation from '../../../concreteStructure/ConcreteStructureListView/NewConcreteStructure/StructureInformation';
import { concreteStructureService } from '../../../../services'
import { formError, toSecond } from '../../../../utils'
import Grow from '@material-ui/core/Grow'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const EditCastingTime = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose, open, concreteStructure, setConcreteStructure, changeSignal, setChangeSignal, token } = props;
  
  return (
    <Formik
      initialValues={{
        castingDate: "",
      }}
      validationSchema={ConcreteStructureSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          values.castingDate = (new Date(values.castingDate)).toString()
          const response = await concreteStructureService.updateConcreteStructure(
            concreteStructure.id,
            values,
            token
          );
          concreteStructure.castingDate = response.castingDate;
          setConcreteStructure(concreteStructure);
          setChangeSignal(changeSignal + 1)
          enqueueSnackbar('Successfully update casting date', { variant: 'success' });
          handleClose();
        } catch (e) {
          enqueueSnackbar(formError(e), { variant: 'error' });
        }
        setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <Dialog
          onClose={handleClose}
          aria-labelledby="edit-casting-time"
          open={open}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle id="edit-casting-time" onClose={handleClose}>
            Edit casting time
          </DialogTitle>
          <DialogContent className={classes.root}>
            <StructureInformation {...formikProps} useLocationInput={false}/>
          </DialogContent>
          <DialogActions>
            {formikProps.isSubmitting && <LinearProgress />}
            <br />
            <Box
              p={2}
            >
              <Button
                onClick={handleClose}
                className={classes.backButton}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={formikProps.isSubmitting}
                onClick={formikProps.submitForm}
              >
                Submit
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

const mapStateToProps = state => ({
  token: state.auth.tokens.access.token
})

export default connect(mapStateToProps, undefined)(EditCastingTime)
