import cst from '../constants';

const initialState = {};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case cst.ACTION_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case cst.ACTION_LOGOUT:
      return {
        ...state,
        tokens: {
          access: {},
          refresh: {}
        }
      }
    case cst.ACTION_UPDATE_USER_INFO:
      return {
        ...state,
        user: action.payload
      }
    default:
      return state
  }
};
