import * as Yup from 'yup';
import cst from '../../../../constants'
import { showCementChoice } from './ConcreteInformation'

export const ConcreteFields = [
  'portland',
  'corseAggregate',
  'flyAsh',
  'retardingAdmixture',
  'water',
  'fineAggregate',
  'acceleratingAdmixture',
  'concreteGrade',
  'Q',
  'RT',
  'Su',
  'K',
  'M0',
  'Offset'
];
const concreteSchema = {};
ConcreteFields.forEach((key, index) => {
  concreteSchema[key] = Yup
      .number()
  /*if (index < ConcreteFields.length - 4)*/
  concreteSchema[key] = concreteSchema[key].min(0).required('Required');
});

const ConcreteStructureSchema = Yup.object({
  location: Yup.string().required('Required'),
  cementType: Yup
      .string()
      .nullable()
      .when('concreteSupplier', {
        is: val => showCementChoice(val) === true,
        then: Yup.string().required('Required')
      }),
  concreteSupplier: Yup.string().nullable(),
  projectId: Yup.string(),
  castingDate: Yup.date()
      .typeError('Must be formatted as MM/DD/YYYY')
      .required('Required'),
  property: Yup.object().when('concreteSupplier', {
    is: cst.CREATE_VALUE,
    then: Yup.object(concreteSchema)
  })
});
export default ConcreteStructureSchema;
