import React, {useEffect} from 'react'
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Edit from '@material-ui/icons/Edit';
import {useSnackbar} from 'notistack'
import clsx from 'clsx';
import {useNavigate} from 'react-router-dom'
import {
  Avatar, Box, Card, CardContent, makeStyles, Typography
} from '@material-ui/core';
import {grey, red} from '@material-ui/core/colors';
import AssignmentIcon from '@material-ui/icons/Assignment'

import './index.css'
import Tooltip from '@material-ui/core/Tooltip'
import CardHeader from '@material-ui/core/CardHeader'
import {formError} from '../../../utils'
import projectService from '../../../services/project.service'
import AlertDialog from '../../../components/Dialog'

const useStyles = makeStyles((theme) => ({
  icon: {
    '& > *': {
      padding: 0
    },
  },
  cardAction: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  marginRight: {
    width: '50%',
    marginRight: theme.spacing(1)
  },
  green: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main
  },
  cardTitle: {
    paddingTop: 4
  }
}));

const ProjectCard = ({ className, project, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [openDialog, setOpenDialog] = React.useState(false);
  const [confirmDelete, setDeleteConfirm] = React.useState(false);

  useEffect(() => {
    (async () => {
      if (!confirmDelete)
        return
      try {
        await projectService.deleteProject(project.id)
        enqueueSnackbar(`Successfully delete project ${project.name}`, { variant: 'success' });
        window.location.reload()
      } catch (e) {
        enqueueSnackbar(formError(e), { variant: 'error' });
      }
    })()
  }, [confirmDelete])

  const handleClickProject = async (e) => {
    if (openDialog)
      return
    navigate(`/app/projects/${project.id}`)
  }

  const handleDeleteProject = async (e) => {
    e.stopPropagation()
    setOpenDialog(true)
  }

  const handleEditProject = async (e) => {
    e.stopPropagation()
  }

  return (
    <Box
      onClick={handleClickProject}
      component={Card}
      boxShadow={24}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <AlertDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setDeleteConfirm={setDeleteConfirm}
        content="This action will delete this project, along with all of its concrete structures and sensors. Are you sure?"
        header="Confirm delete project"
      />
      <CardHeader
        avatar={(
          <Avatar className={classes.green}>
            <AssignmentIcon fontSize="small"/>
          </Avatar>
        )}
        title={(
          <Tooltip title="Click to view its concrete structures" placement="top">
            <Typography
              color="textPrimary"
              variant="h5"
              className={classes.cardTitle}
            >
              {project.name}
            </Typography>
          </Tooltip>
        )}
        action={(
          <div
            className={classes.cardAction}
          >
            <Tooltip title="Edit">
              <IconButton
                style={{ color: grey, padding: '3px 0px' }}
                aria-label="edit"
                className={classes.icon}
                onClick={handleEditProject}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                className={classes.icon}
                style={{ color: red[400], padding: '3px 0px' }}
                aria-label="delete"
                onClick={handleDeleteProject}
              >
                <ClearAllIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      />
      <CardContent>
        {/*        <Box
          display="flex"
          justifyContent="center"
          mb={3}
        >

        </Box> */}
        <Box>
          <Typography
            color="textSecondary"
            variant="body2"
            display="inline"
            className={classes.marginRight}
          >
            Contractor:
          </Typography>
          <Typography
            display="inline"
            color="textPrimary"
            variant="body2"
          >
            {project.contractor}
          </Typography>
          <br/>
          <Typography
            color="textSecondary"
            variant="body2"
            display="inline"
            className={classes.marginRight}
          >
            Project Manager:
          </Typography>
          <Typography
            display="inline"
            color="textPrimary"
            variant="body2"
          >
            {project.projectManager}
          </Typography>
        </Box>
      </CardContent>
      <Box flexGrow={1} />
    </Box>
  );
};

ProjectCard.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired
};

export default ProjectCard;
