import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import Selector from './component/Selector';
import { formatDateTimeFromSecondUnixTimeOrDate } from '../../../utils';

const useStyles = makeStyles(() => ({
  root: {}
}));

function genData(sensorData, index, color) {
  return sensorData.noOfMoisture > index
    ? {
        data: sensorData.moisture[index] || [],
        label: `Moisture (datapoint ${index + 1})`,
        borderColor: colors[color][500],
        backgroundColor: 'transparent'
      }
    : {};
}

const Moisture = ({
  sensorData = {},
  selectedSensor,
  activeSensors,
  setSelectedSensor,
  className,
  concreteStructure,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  if (!sensorData.moisture || sensorData.moisture?.length === 0) return null;

  const selectedSensorObj =
    activeSensors.find(s => s.id === selectedSensor.id) || {};
  sensorData.noOfMoisture = Math.min(
    sensorData.moisture.length,
    selectedSensorObj.noOfMoisture || 1000
  );

  const datasets =
    typeof sensorData.moisture[0] === 'number'
      ? [
          {
            data: sensorData.moisture || [],
            label: 'Moisture',
            borderColor: colors.deepPurple[500],
            backgroundColor: 'transparent'
          }
        ]
      : [
          genData(sensorData, 0, 'purple'),
          genData(sensorData, 1, 'yellow'),
          genData(sensorData, 2, 'red'),
          genData(sensorData, 3, 'blue'),
          genData(sensorData, 4, 'green')
        ];
  const data = {
    datasets,
    labels: sensorData.labelsMoisture.map(v => v.toFixed(3)) || []
  };
  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.primary,
            autoSkip: true,
            maxTicksLimit: 5
          },
          gridLines: {
            display: false
          },
          scaleLabel: {
            display: true,
            labelString: 'Age (days)',
            fontSize: 12,
            color: theme.palette.text.primary
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.primary,
            beginAtZero: true,
            min: 0,
            stepSize: 20
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          scaleLabel: {
            display: true,
            labelString: 'Moisture',
            fontSize: 12,
            color: theme.palette.text.primary
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
      callbacks: {
        title(tooltipItem) {
          return `${tooltipItem[0].xLabel} days`;
        },
        afterTitle(tooltipItem) {
          return `${formatDateTimeFromSecondUnixTimeOrDate(
            sensorData.time[tooltipItem[0].index],
            false,
            true
          )}`;
        }
      }
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <>
            <Selector
              selectedSensor={selectedSensor}
              activeSensors={activeSensors}
              setSelectedSensor={setSelectedSensor}
            />
            {/* <ToCsv
              dataForChart={data}
            /> */}
          </>
        }
        title="Moisture"
      />
      <Divider />
      <CardContent>
        <Box height={210} position="relative">
          <Line data={data} options={options} />
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

Moisture.propTypes = {
  className: PropTypes.string
};

export default Moisture;
