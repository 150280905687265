import cst from '../constants';

const initialState = { loading: false };

export const project = (state = initialState, action) => {
  switch (action.type) {
    case cst.ACTION_GET_PROJECT:
      return {
        ...state,
        currentProject: action.payload
      };
    default:
      return state
  }
};
