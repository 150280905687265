import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchBox from '../../../components/SearchBox';
import NewProject from './NewProject';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3)
  },
  searchBox: {
    marginRight: theme.spacing(1)
  },
}));

const Toolbar = ({ className, user, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <SearchBox />
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutlineIcon/>}
            size={"small"}
            onClick={handleClickOpen}
          >
            Add Project
          </Button>
        </Box>
      </div>
      <NewProject
        open={open}
        user={user}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
