/**
 * Parse val to number. If not possible, return defaultVal
 * @param val
 */
function parseIntHandler(val, defaultVal) {
  try {
    return JSON.parse(val)
  } catch (e) {
    return defaultVal
  }
}

module.exports = {
  URL: process.env.REACT_APP_API_URL,
  DEFAULT_IMAGE_PATH: '/static/images/products/product_2.png',
  LOWER_BOUND_SENSOR_TEMP: parseIntHandler(process.env.REACT_APP_LOWER_BOUND_SENSOR_TEMP, 20),
  UPPER_BOUND_SENSOR_TEMP: parseIntHandler(process.env.REACT_APP_UPPER_BOUND_SENSOR_TEMP, 100)
}
