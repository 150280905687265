import { handleResponse } from './helper'
import { URL } from '../config'

async function createConcreteSupplier(concreteSupplierBody) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(concreteSupplierBody),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/concreteSuppliers`, requestOptions)
    .then(handleResponse);
}

const getConcreteSuppliers = async (query = {}) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/concreteSuppliers?${new URLSearchParams(query)}`, requestOptions)
    .then(handleResponse);
};

const getConcreteSupplier = async (id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/concreteSuppliers/${id}`, requestOptions)
    .then(handleResponse);
};

const concreteSupplier = {
  createConcreteSupplier,
  getConcreteSuppliers,
  getConcreteSupplier
};

export default concreteSupplier;
