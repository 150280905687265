import React, { useCallback, useState } from 'react'
import { Field, Form } from 'formik';
import MuiTextField from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui'
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import makeStyles from '@material-ui/styles/makeStyles'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import { debounce } from 'lodash'
import sensorService from '../../../../services/sensor.service'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formGroupRoot: {
    display: 'flex',
    flexWrap: 'unset',
    flexDirection: 'row'
  },
  formControlLabelRoot: {
    flexGrow: 1
  }
}));

const CustomTextField = (_props) => {
  return (
    <MuiTextField
      fullWidth
      {...fieldToTextField(_props)}
    />
  );
}

const aggregateDatapoints = (sensors) => {
  return sensors.reduce((acc, current) => {
    (current.datapoints || []).forEach(v => {
      acc[v] = current.concreteStructure.location
    })
    return acc
  }, {})
}

const SensorInformation = (props) => {
  const classes = useStyles();
  const { setFieldValue, values } = props;
  const [sensors, setSensors] = useState([])
  const [datapoints, setDatapoints] = useState([])
  const datapointsContainer = aggregateDatapoints(sensors)

  const handleChangeDatapoints = (index) => {
    const newDatapoints = datapoints.includes(index)
      ? datapoints.filter(v => v !== index) : datapoints.concat(index)
    newDatapoints.sort()
    setFieldValue('datapoints', newDatapoints)
    setDatapoints(newDatapoints)
  }

  const handleChangeDevice = useCallback(
    debounce(async (device) => {
      if (!device)
        return
      const sensorsByDevice = await sensorService.getSensors({
        device,
        deactivatedAt: 0,
        populate: 'concreteStructure',
        select: 'concreteStructure datapoints'
      })
      setSensors(sensorsByDevice.results)
    }, 1000),
    []
  );

  return (
    <Grid
      component={Form}
      container
      spacing={2}
    >
      <Grid item xs={6}>
        <Field
          component={CustomTextField}
          name="device"
          label="Sensor ID"
          autoFocus
          onChange={async (e) => {
            setFieldValue('device', e.target.value);
            setFieldValue('datapoints', [])
            setDatapoints([])
            handleChangeDevice(e.target.value)
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={CustomTextField}
          name="remark"
          label="Remark"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Select datapoints</FormLabel>
          <FormGroup classes={{
            root: classes.formGroupRoot
          }}
          >
            {
              [0, 1, 2, 3, 4].map(v => {
                const title = datapointsContainer[v]
                const checked = !!datapoints.includes(v)
                return (
                  <Tooltip title={title ? `Used in Concrete Structure ${title}` : ''}>
                    <FormControlLabel
                      classes={{
                        root: classes.formControlLabelRoot
                      }}
                      control={(
                        <Checkbox
                          disabled={!values.device || title !== undefined}
                          checked={checked}
                          onChange={() => handleChangeDatapoints(v)}
                        />
                      )}
                      label={v + 1}
                    />
                  </Tooltip>
                )
              })
            }
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <InputLabel id="no-of-moisture-label">Number of moisture datapoint</InputLabel>
        <Field
          className={classes.formControl}
          as={Select}
          name="noOfMoisture"
          labelId="no-of-moisture-label"
        >
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </Field>
      </Grid>
    </Grid>
  )
}

export default SensorInformation;
