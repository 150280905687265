import { handleResponse } from './helper'
import { URL } from '../config'

async function createConcreteStructure(concreteStructureBody) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(concreteStructureBody),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/concreteStructures`, requestOptions)
    .then(handleResponse);
}

const getConcreteStructures = async (query = {}) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/concreteStructures/?${new URLSearchParams(query)}`, requestOptions)
    .then(handleResponse);
};

const getConcreteStructure = async (id, query) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/concreteStructures/${id}?${new URLSearchParams(query)}`, requestOptions)
    .then(handleResponse);
};

const updateConcreteStructure = async (id, concreteStructureBody, accessToken) => {
  const headers = {
    Accept: 'application/json',
    "Content-Type": "application/json",
  }
  if (accessToken)
    headers.Authorization = `Bearer ${accessToken}`

  const requestOptions = {
    method: 'PATCH',
    headers,
    body: JSON.stringify(concreteStructureBody)
  };
  return fetch(`${URL}/concreteStructures/${id}`, requestOptions)
    .then(handleResponse);
};

const deleteConcreteStr = async (id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/concreteStructures/${id}`, requestOptions)
    .then(handleResponse);
};

const resource = {
  createConcreteStructure,
  getConcreteStructures,
  getConcreteStructure,
  updateConcreteStructure,
  deleteConcreteStr
};

export default resource;
