import * as Yup from 'yup';

const SensorSchema = Yup.object({
  device: Yup.string().required('Required'),
  project: Yup.string().required('Required'),
  concreteStructure: Yup.string().required('Required'),
  remark: Yup.string(),
  noOfDatapoint: Yup.number(),
  noOfMoisture: Yup.number(),
  datapoints: Yup.array().default([]).required('Required')
});

export default SensorSchema;
