import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom'
import clsx from 'clsx'
import { useParams, useNavigate } from 'react-router-dom'
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import { useSnackbar } from 'notistack';
import cst from 'src/constants'
import Toolbar from './Toolbar';
import ProjectCard from './ProjectCard';
import SubHeader from '../../../components/SubHeader';
import { projectService } from '../../../services';
import IconBreadcrumbs from '../../../components/Breadcrumbs'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    marginBottom: theme.spacing(3)
  },
  productCard: {
    height: '100%',

  }
}));

const ProjectList = ({ user }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(useParams().page);
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [count, setCount] = useState(1);

  useEffect(() => {
    (async () => {
      try {
        const response = await projectService.getProjects({
          user: user.id,
          sortBy: '-createdAt',
          limit: 6,
          page: page || 1
        });
        setProjects(response.results);
        setCount(response.totalPages);
      } catch (e) {
        const message = e.message ? e.message : cst.ERROR_DEFAULT;
        enqueueSnackbar(message, { variant: 'error' })
      }
    })();
  }, [page]);

  const handleChangePage = async (event, newPage) => {
    navigate(`/app/projects/page/${newPage}`)
    setPage(newPage);
  }
  return (
    <div style={{ position: 'relative' }}>
      <Page
        className={classes.root}
        title="Projects"
      >
        <Container maxWidth={false}>
          <Toolbar user={user} />
          <Box mt={5} mx={10}>
            <Grid
              container
              spacing={7}
            >
              {projects.map((project) => (
                <Grid
                  item
                  key={project.id}
                  lg={4}
                  md={6}
                  xs={12}
                  className={clsx("hover-zoom")}
                >
                  <Zoom timeout={1000} in>
                    <ProjectCard
                      className={clsx(classes.productCard)}
                      project={project}
                    />
                  </Zoom>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            mt={3}
            display="flex"
            justifyContent="center"
          >
            <Pagination
              color="secondary"
              count={count}
              page={page || 1}
              size="small"
              onChange={handleChangePage}
            />
          </Box>
        </Container>
      </Page>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
})
export default connect(mapStateToProps, undefined)(ProjectList);
