import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { Provider } from 'react-redux';
import { useRoutes, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';

import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import reduxStore from './store';

const { store, persistor } = reduxStore();
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

const App = () => {
  const routing = useRoutes(routes);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          maxSnack={3}
          dense
          ref={notistackRef}
          action={key => <Button onClick={onClickDismiss(key)}>Dismiss</Button>}
        >
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            {routing}
          </ThemeProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
