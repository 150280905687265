import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  colors,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import {
  deepCopy,
  formatDateTimeFromSecondUnixTimeOrDate
} from '../../../utils';
import { generateDatepointsIndex } from './helper';
import Selector from './component/Selector';
import { MAIN_VIEW_DIFF, MAIN_VIEW_STRENGTH } from '.';
import Switcher from './component/Switcher';

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 0
  },
  root: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  downloadIcon: {
    marginTop: theme.spacing(1)
  }
}));

const Difference = ({
  className,
  sensor,
  selectedSensor,
  setSelectedSensor,
  activeSensors,
  mainView,
  setMainView,
  concreteStructure,
  sensorData = {},
  allSensorData,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [filterData, setFilterData] = useState([]);
  const [positions, setPositions] = useState([]);
  const dataIndex = generateDatepointsIndex({ ...sensorData.sensor });

  const checkValue = value => {
    return (
      value >= parseInt(process.env.REACT_APP_LOWER_BOUND_SENSOR_TEMP) &&
      value <= parseInt(process.env.REACT_APP_UPPER_BOUND_SENSOR_TEMP)
    );
  };

  const transformDataToText = data => {
    let text = '';
    if (data.length === 0) {
      return text;
    }
    text += data[0];
    for (let i = 1; i < data.length; i++) {
      text += `, ${data[i]}`;
    }
    return text;
  };

  useEffect(() => {
    setFilterData([]);
    setPositions([]);
  }, [sensorData]);
  useEffect(() => {
    if (
      Array.isArray(sensorData.allTemperature) &&
      sensorData.allTemperature.length > 1
    ) {
      let data = [];
      let position = [];
      for (let i = 0; i < sensorData.allTemperature[0].length; i++) {
        let min = parseInt(process.env.REACT_APP_UPPER_BOUND_SENSOR_TEMP) + 1;
        let max = parseInt(process.env.REACT_APP_LOWER_BOUND_SENSOR_TEMP) - 1;
        let posMin = [];
        let posMax = [];
        for (let j = 0; j < sensorData.sensor.datapoints.length; j++) {
          //check with min temperature value
          if (
            sensorData.allTemperature[j][i] === min &&
            checkValue(sensorData.allTemperature[j][i])
          ) {
            min = sensorData.allTemperature[j][i];
            posMin = [...posMin, dataIndex[j]];
          } else if (
            sensorData.allTemperature[j][i] < min &&
            checkValue(sensorData.allTemperature[j][i])
          ) {
            min = sensorData.allTemperature[j][i];
            posMin = [dataIndex[j]];
          }
          //check with max temperature value
          if (
            sensorData.allTemperature[j][i] === max &&
            checkValue(sensorData.allTemperature[j][i])
          ) {
            max = sensorData.allTemperature[j][i];
            posMax = [...posMax, dataIndex[j]];
          } else if (
            sensorData.allTemperature[j][i] > max &&
            checkValue(sensorData.allTemperature[j][i])
          ) {
            max = sensorData.allTemperature[j][i];
            posMax = [dataIndex[j]];
          }
        }
        if (
          min >= parseInt(process.env.REACT_APP_LOWER_BOUND_SENSOR_TEMP) &&
          max <= parseInt(process.env.REACT_APP_UPPER_BOUND_SENSOR_TEMP) &&
          posMin.length !== 0
        ) {
          data.push(Math.round((max - min) * 100) / 100);
        } else {
          data.push(0);
        }
        position.push({
          posMin: posMin,
          posMax: posMax
        });
      }

      setFilterData(data);
      setPositions(position);
    } else {
      setFilterData([]);
      setPositions([]);
    }
  }, [sensorData]);

  const getDataForChart = (toFix = 2) => {
    let datasets = [
      {
        data: filterData,
        borderColor: colors.green[500],
        backgroundColor: 'transparent'
      }
    ];
    const data = {
      datasets: deepCopy(datasets),
      labels: sensorData.allLabels
        ? sensorData.allLabels.map(v => v.toFixed(toFix))
        : []
    };

    return deepCopy(data);
  };

  const dataForChart = getDataForChart();
  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.primary,
            autoSkip: true,
            maxTicksLimit: 10
          },
          gridLines: {
            display: false
          },
          scaleLabel: {
            display: true,
            labelString: 'Age (days)',
            fontSize: 16,
            color: theme.palette.text.primary
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.primary,
            beginAtZero: false,
            min: 0,
            max: 80
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          scaleLabel: {
            display: true,
            labelString: 'Difference (℃)',
            fontSize: 16,
            color: theme.palette.text.primary
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
      callbacks: {
        title(tooltipItem) {
          return `${tooltipItem[0].xLabel} days`;
        },
        label(tooltipItem) {
          let index = tooltipItem.index;
          if (
            positions[index].posMin.length + positions[index].posMax.length >
            2
          ) {
            return `℃ ${
              sensorData.sensor.device
            }(datapoint min: ${transformDataToText(
              positions[index].posMin
            )} and max: ${transformDataToText(positions[index].posMax)}): ${
              tooltipItem.value
            }`;
          } else if (
            positions[index].posMin.length + positions[index].posMax.length ===
            2
          ) {
            if (positions[index].posMin[0] !== positions[index].posMax[0]) {
              return `℃ ${
                sensorData.sensor.device
              }(datapoint min: ${transformDataToText(
                positions[index].posMin
              )} and max: ${transformDataToText(positions[index].posMax)}): ${
                tooltipItem.value
              }`;
            } else {
              return `℃ ${
                sensorData.sensor.device
              }(datapoint min: ${transformDataToText(
                positions[index].posMin
              )} and max: ${transformDataToText(
                positions[index].posMax
              )}): NIL`;
            }
          } else
            return `℃ ${sensorData.sensor.device}(datapoint min: NIL and max: NIL): NIL`;
        },
        afterTitle(tooltipItem) {
          return `${formatDateTimeFromSecondUnixTimeOrDate(
            sensorData.time[tooltipItem[0].index],
            false,
            true
          )}`;
        }
      }
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <Selector
            selectedSensor={selectedSensor}
            activeSensors={activeSensors}
            setSelectedSensor={setSelectedSensor}
          />
        }
        title={
          <Box display={'flex'}>
            {mainView === MAIN_VIEW_DIFF && (
              <Switcher setMainView={setMainView} dest={MAIN_VIEW_STRENGTH} />
            )}
            <Typography variant={'h4'}>Difference</Typography>
          </Box>
        }
      />
      <CardContent className={classes.content}>
        <Box height={330} mt={4} position="relative">
          <Line data={dataForChart} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

Difference.propTypes = {
  className: PropTypes.string
};

export default Difference;
