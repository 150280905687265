import React, { useState } from 'react';
import { connect } from 'react-redux'
import clsx from 'clsx';
import ChipInput from 'material-ui-chip-input'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import { useSnackbar } from 'notistack'
import Typography from '@material-ui/core/Typography'
import { userAction } from '../../../actions'
import { formError } from '../../../utils'

const useStyles = makeStyles(() => ({
  root: {},
  termsOfService: {
    paddingTop: '0 !important',
    textAlign: 'justify'
  }

}));

const ProfileDetails = ({
  className, user, updateUserInfo, ...rest
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  if (typeof user.phone === 'string') {
    user.phone = [user.phone]
  }
  const [values, setValues] = useState(user);
  const [helperText, setHelperText] = useState("")

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleBeforeAdd = (phone) => {
    if (!phone || !phone.match(/\+{0,1}65\d{5,10}$/g)) {
      return false;
    }
    return true;
  }

  const handlePhoneVerification = (e) => {
    let phone = e.target.value;
    if (phone) {
      if (!phone.startsWith('+'))
        phone = `+${phone}`

      if (["+", "+6", "+65"].includes(phone)) {
        setHelperText("")
        return;
      }
      if (!phone.match(/\+{0,1}65\d{0,15}$/g)) {
        if (phone.length <= 3) {
          setHelperText("Phone number should start with country code 65")
          return;
        }
        if (!phone.startsWith('+65')) {
          setHelperText("Phone number should start with country code 65")
          return;
        }
        setHelperText("Phone number must consist of only digits")
        return
      }
    }
    setHelperText("")
  }

  const handleAddNewPhone = (phone) => {
    if (phone && !phone.startsWith('+'))
      phone = `+${phone}`
    setValues({
      ...values,
      phone: values.phone.concat([phone])
    })
  }

  const handleDeletePhone = (phone, index) => {
    values.phone.splice(index, 1)
    setValues({
      ...values,
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await updateUserInfo(values)
      enqueueSnackbar("Successfully update user information", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(formError(e), { variant: "error" });
    }
  }
  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <ChipInput
                fullWidth
                value={values.phone}
                onBeforeAdd={handleBeforeAdd}
                onUpdateInput={handlePhoneVerification}
                onAdd={(chip) => handleAddNewPhone(chip)}
                onDelete={(chip, index) => handleDeletePhone(chip, index)}
                label="Mobile Phone Number"
                helperText={helperText}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              variant={"body1"}
              className={classes.termsOfService}
            >
              By registering and entering your email address & phone number you agree to be contacted
              both by email & phone. Message and data rates apply.
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user
})

const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (payload) => dispatch(userAction.updateUserInfo(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails)
