const cst = {
  ACTION_LOADING: 'loading',
  ACTION_LOADED: 'loaded',
  ACTION_GET_PROJECT: 'ACTION_GET_PROJECT',
  ACTION_ACTIVATE_SENSOR: 'ACTIAVATE_SENSOR',
  ACTION_DEACTIVATE_SENSOR: 'DEACTIVATE_SENSOR',
  ACTION_LOGIN_SUCCESS: 'ACTION_LOGIN_SUCCESS',
  ACTION_UPDATE_USER_INFO: 'ACTION_UPDATE_USER_INFO',
  ACTION_GET_CONCRETE_STRUCTURE: 'ACTION_GET_CONCRETE_STRUCTURE',
  ACTION_LOGOUT: 'ACTION_LOGOUT',
  ERROR_DEFAULT: 'There is some errors, please try again later!',

  NA_VALUE: 'NA',
  SENSOR_AVERAGE: 'SENSOR_AVERAGE',
  NONE_VALUE: 'NONE',
  CREATE_VALUE: 'CREATE',

  UPDATE_RECENT_PAGE_VALUE: 'UPDATE_RECENT_PAGE_VALUE',
  RECENT_PAGE_VALUE_CENSOR: 'RECENT_PAGE_VALUE_CENSOR',
  RECENT_PAGE_VALUE_CS: 'RECENT_PAGE_VALUE_CS',
  RECENT_PAGE_VALUE_PROJECT: 'RECENT_PAGE_VALUE_PROJECT',

  SENSOR_TYPE_TEMP: 't',
  SENSOR_TYPE_MOISTURE: 'm'
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum defaultISensorFormulaParams {
  Q = 4250,
  RT = 20,
  Su = 71.533,
  K = 0.2297,
  M0 = 0.101,
  Offset = 0
}

export default cst;
