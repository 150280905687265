import React from 'react';
import { Field, Form } from 'formik';
import MuiTextField from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui';
import Grid from '@material-ui/core/Grid';

const CustomTextField = (props) => {
  return <MuiTextField fullWidth {...fieldToTextField(props)} />;
}
const StructureInformation = (props) => {
  const { useLocationInput = true} = props;
  return (
    <Grid
      component={Form}
      container
      spacing={2}
    >
      {useLocationInput && <Grid item xs={6}>
        <Field
          component={CustomTextField}
          name="location"
          label="Location"
        />
      </Grid>}
      <Grid item xs={useLocationInput ? 6 : 12}>
        <Field
          component={CustomTextField}
          name="castingDate"
          label="Casting Date"
          type="datetime-local"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </Grid>
  )
}

export default StructureInformation;
