import cst from '../constants';

const initialState = { loading: false };

export const concreteStructure = (state = initialState, action) => {
  switch (action.type) {
    case cst.ACTION_GET_CONCRETE_STRUCTURE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state
  }
};
