import cst from '../constants';
import { getCurrentTime } from '../utils';

function activateSensor() {
  return async dispatch => {
    return dispatch({
      type: cst.ACTION_ACTIVATE_SENSOR,
      payload: getCurrentTime()
    })
  }
}

function deactivateSensor() {
  return async dispatch => {
    return dispatch({
      type: cst.ACTION_DEACTIVATE_SENSOR,
      payload: getCurrentTime()
    })
  }
}
export const sensorAction = {
  activateSensor,
  deactivateSensor
}
