import React from "react"
import {makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import {IconButton, Tooltip} from "@material-ui/core";
import {redBtnTheme} from "../../../../theme/customTheme";
import {SwitchCamera} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  switchBn: {
    marginRight: theme.spacing(1)
  }
}));

export const Switcher = (props) => {
  const classes = useStyles();
  const {setMainView, dest} = props;
  return (
      <Tooltip title={'Click to switch between concrete strength and temperature'}>
        <IconButton
            className={classes.switchBn}
        >
          <MuiThemeProvider theme={redBtnTheme}>
            <SwitchCamera
                color={'secondary'}
                onClick={() => setMainView(dest)}
            />
          </MuiThemeProvider>
        </IconButton>
      </Tooltip>
  )
}
export default Switcher
