import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Page from 'src/components/Page';
import { useSnackbar } from 'notistack';
import { Edit } from '@material-ui/icons';
import ConcreteStrength from './ConcreteStrength';
import Temperature from './Temperature';
import { concreteStructureService, sensorService } from '../../../services';
import { deepCopy, formError } from '../../../utils';
import ConcreteStructureOverview from '../../sensor/SensorListView/ConcreteStructureOverview';
import { getConcreteStructureOverview } from '../../concreteStructure/ConcreteStructureListView/helper';
import {
  getAverageSensorData,
  syncLocalMetaValueToRemote,
  transformSensorData
} from './helper';
import cst from '../../../constants';
import Loader from '../../../components/Loader';
import Moisture from './Moisture';
import IconBreadcrumbs from '../../../components/Breadcrumbs';
import EditCastingTime from '../../sensor/SensorListView/EditCastingTime';
import SensorListView from '../../sensor/SensorListView';
import ExportSensorData from './component/ExportSensorData';
import Difference from './Difference';

export const MAIN_VIEW_TEMP = 'MAIN_VIEW_TEMP';
export const MAIN_VIEW_STRENGTH = 'MAIN_VIEW_STRENGTH';
export const MAIN_VIEW_DIFF = 'MAIN_VIEW_DIFF';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  keyInformation: {
    marginBottom: theme.spacing(3)
  },
  btn: {
    float: 'right',
    margin: '0 0 4px 8px'
  },
  mainDisplay: {
    marginBottom: theme.spacing(3)
  }
}));

const Dashboard = props => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { concreteStructureId } = useParams();
  const { token } = props;
  const [project, setProject] = useState(() => ({}));
  const [transformedSensorData, setData] = useState([]);
  const [concreteStructure, setConcreteStructure] = useState({});
  const [selectedSensor, setSelectedSensor] = useState({
    id: cst.SENSOR_AVERAGE
  });
  const [showEditCastingTime, setShowEditCastingTime] = useState(false);
  const [mainView, setMainView] = useState(MAIN_VIEW_STRENGTH);

  const [fetchConcreteStructure, setFetchConcreteStructure] = useState(0);
  const [idChosenAll, setIdChosenAll] = useState(null);

  /**
   * Change = 0 means that there is no change => No update, or fetch action will be executed
   * @param change
   */
  const dispatchFetchConcreteStructure = change => {
    setFetchConcreteStructure(fetchConcreteStructure + change);
  };

  const handleOpenEditCastingTime = () => {
    setShowEditCastingTime(true);
  };
  const handleCloseEditCastingTime = () => {
    setShowEditCastingTime(false);
  };

  useEffect(() => {
    /*    if (location && location.state && fetchConcreteStructure === 0) {
      return;
    } */
    (async () => {
      try {
        const response = await concreteStructureService.getConcreteStructure(
          concreteStructureId,
          {
            populate: 'sensor cementType project'
          }
        );
        setConcreteStructure(response);
        setProject(response.project);
      } catch (e) {
        enqueueSnackbar(formError(e), { variant: 'error' });
      }
    })();
  }, [fetchConcreteStructure]);

  let childSensors;
  if (concreteStructure.id) childSensors = concreteStructure.sensor; // .filter(ss => isActive(ss));

  useEffect(() => {
    (async () => {
      if (!childSensors) return;
      // Todo: optimize the case sensors with same device
      const promises = childSensors.map(ss =>
        sensorService.getSensorData({
          device: ss.device,
          startTime: ss.activatedAt,
          endTime: ss.deactivatedAt
        })
      );
      try {
        const sensorResponse = await Promise.all(promises);
        const temp = sensorResponse.map((v, index) => {
          return transformSensorData(v, concreteStructure, childSensors[index]);
        });
        setData(temp);

        // Now, sync sensor's meta value from local to remote to ensure consitency
        const concreteStructureSync = await syncLocalMetaValueToRemote(
          temp,
          concreteStructure,
          token
        );
        if (concreteStructureSync !== false)
          setConcreteStructure(concreteStructureSync);
      } catch (e) {
        console.log(e);
        enqueueSnackbar(formError(e), { variant: 'error' });
      }
    })();
  }, [concreteStructure.sensor]);

  useEffect(() => {
    if (transformedSensorData?.length > 0) {
      let index = transformedSensorData.findIndex(
        ss => ss.sensor.datapoints.length > 1
      );
      setIdChosenAll(index !== -1 ? index : null);
    } else {
      setIdChosenAll(null);
    }
  }, [transformedSensorData]);

  if (!concreteStructure.id) return <Loader />;

  const concreteStructureOverview = getConcreteStructureOverview(
    concreteStructure
  );
  const indexOfSelectedSensor = childSensors.findIndex(
    ss => ss.id === selectedSensor.id
  );

  const sensorData =
    selectedSensor.id === cst.SENSOR_AVERAGE
      ? deepCopy(getAverageSensorData(transformedSensorData))
      : deepCopy(transformedSensorData[indexOfSelectedSensor]);

  const sensorIdChosenAll = transformedSensorData?.map(ss => {
    if (ss.sensor.datapoints.length > 1) {
      return ss.sensor.id;
    }
  });

  const sensorDiffData =
    selectedSensor.id === cst.SENSOR_AVERAGE
      ? transformedSensorData.length > 0
        ? transformedSensorData[idChosenAll ? idChosenAll : 0]
        : deepCopy(getAverageSensorData(transformedSensorData))
      : deepCopy(transformedSensorData[indexOfSelectedSensor]);

  return (
    <div style={{ position: 'relative' }}>
      <IconBreadcrumbs
        path={[
          { link: `/app/projects/${project.id}`, title: `${project.name}` },
          {
            link: `/app/dashboard/${concreteStructure.id}`,
            title: `${concreteStructure.location}`
          },
          {
            link: `/app/dashboard/${concreteStructure.id}`,
            title: `${selectedSensor.device || 'Average'}`
          }
        ]}
      />
      <Page className={classes.root} title="Dashboard">
        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={3} style={{ marginBottom: 16 }}>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={12}>
                  <ExportSensorData
                    className={classes.btn}
                    concreteStructure={concreteStructure}
                    dataset={transformedSensorData}
                    activeSensors={childSensors}
                  />
                </Grid>
                <Grid item xs={12} className={classes.mainDisplay}>
                  {/* <Difference
                    mainView={mainView}
                    setMainView={setMainView}
                    concreteStructure={concreteStructure}
                    selectedSensor={selectedSensor}
                    activeSensors={childSensors}
                    setSelectedSensor={setSelectedSensor}
                    sensorData={sensorData}
                    allSensorData={deepCopy(transformedSensorData)}
                  /> */}
                </Grid>
                <Grid item xs={12} className={classes.mainDisplay}>
                  {mainView === MAIN_VIEW_STRENGTH ? (
                    <ConcreteStrength
                      mainView={mainView}
                      setMainView={setMainView}
                      concreteStructure={concreteStructure}
                      selectedSensor={selectedSensor}
                      activeSensors={childSensors}
                      setSelectedSensor={setSelectedSensor}
                      sensorData={sensorData}
                    />
                  ) : mainView === MAIN_VIEW_TEMP ? (
                    <Temperature
                      mainView={mainView}
                      setMainView={setMainView}
                      sensorData={sensorData}
                      concreteStructure={concreteStructure}
                      selectedSensor={selectedSensor}
                      activeSensors={childSensors}
                      setSelectedSensor={setSelectedSensor}
                    />
                  ) : (
                    <Difference
                      mainView={mainView}
                      setMainView={setMainView}
                      sensorData={
                        selectedSensor.id === cst.SENSOR_AVERAGE
                          ? sensorDiffData
                          : sensorData
                      }
                      concreteStructure={concreteStructure}
                      selectedSensor={selectedSensor}
                      activeSensors={childSensors}
                      setSelectedSensor={setSelectedSensor}
                      // allSensorData={transformedSensorData}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <SensorListView
                    concreteStructure={concreteStructure}
                    dispatchFetchConcreteStructure={
                      dispatchFetchConcreteStructure
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Tooltip
                    title="This action will recalculate concrete structure strength"
                    placement="top"
                  >
                    <Button
                      color="primary"
                      size={'medium'}
                      variant={'contained'}
                      startIcon={<Edit />}
                      onClick={handleOpenEditCastingTime}
                      className={classes.btn}
                    >
                      Edit casting time
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} className={classes.keyInformation}>
                  <ConcreteStructureOverview
                    concreteStructureOverview={concreteStructureOverview}
                    concreteStructure={concreteStructure}
                  />
                </Grid>
                <Grid item xs={12}>
                  {mainView === MAIN_VIEW_STRENGTH ? (
                    <Temperature
                      mainView={mainView}
                      setMainView={setMainView}
                      sensorData={sensorData}
                      concreteStructure={concreteStructure}
                      selectedSensor={selectedSensor}
                      activeSensors={childSensors}
                      setSelectedSensor={setSelectedSensor}
                    />
                  ) : mainView === MAIN_VIEW_DIFF ? (
                    <ConcreteStrength
                      mainView={mainView}
                      setMainView={setMainView}
                      sensorData={sensorData}
                      concreteStructure={concreteStructure}
                      selectedSensor={selectedSensor}
                      activeSensors={childSensors}
                      setSelectedSensor={setSelectedSensor}
                    />
                  ) : (
                    <Difference
                      mainView={mainView}
                      setMainView={setMainView}
                      sensorData={
                        selectedSensor.id === cst.SENSOR_AVERAGE
                          ? sensorDiffData
                          : sensorData
                      }
                      concreteStructure={concreteStructure}
                      selectedSensor={selectedSensor}
                      activeSensors={childSensors}
                      setSelectedSensor={setSelectedSensor}
                    />
                  )}
                </Grid>
                {/*<Grid item xs={12}>
                    <Moisture
                        sensorData={sensorData}
                        concreteStructure={concreteStructure}
                        selectedSensor={selectedSensor}
                        activeSensors={childSensors}
                        setSelectedSensor={setSelectedSensor}
                    />
                  </Grid>*/}
                {/* <Grid item xs={12}>
                  <Milestone/>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <EditCastingTime
            open={showEditCastingTime}
            setConcreteStructure={setConcreteStructure}
            setChangeSignal={setFetchConcreteStructure}
            changeSignal={fetchConcreteStructure}
            concreteStructure={concreteStructure}
            handleClose={handleCloseEditCastingTime}
          />
        </Container>
      </Page>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.tokens.access.token
});

export default connect(mapStateToProps, undefined)(Dashboard);
