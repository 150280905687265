import typography from './typography';

export default {
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        fontSize: '98%',
        whiteSpace: 'nowrap'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        fontSize: '98%',
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: typography.fontFamily,
        // Body2
        fontSize: '0.875rem',
        lineHeight: 1.43,
        color: '#6c757d',
        fontWeight: 400
      },
      body: {
        color: '#6c757d',
      },
      footer: {
        backgroundColor: 'rgba(0, 0, 0, 0.03)'
      }
    },
    MuiPaper: {
      root: {
        border: '0.5px groove #dee2e6',
        borderRightWidth: 0
      }
    },
    // MuiTypography: {
    //   h6: {
    //     fontFamily: typography.fontFamily,
    //     fontSize: 25,
    //     color: '#222d39',
    //   }
    // },
    MuiToolbar: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.03)'
      }
    },
    MUIDataTableToolbar: {
      titleText: {
        fontSize: '25px !important'
      }
    },
    MuiSvgIcon: {
      root: {
        color: '#3f51b5'
      }
    }
  }
}
