import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as Yup from 'yup'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Field, Form, Formik } from 'formik';
import Box from '@material-ui/core/Box';
import { fieldToTextField } from 'formik-material-ui';
import MuiTextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Divider from '@material-ui/core/Divider';
import { formError } from 'src/utils';
import { ERROR_DEFAULT } from 'src/constants'
import { capitalizeWord } from '../../../utils/getInitials';
import { projectService } from '../../../services';
import LocalSubmitButton from '../../../components/LocalSubmitButton';
import Grow from '@material-ui/core/Grow'

const styles = (theme) => ({
  root: {
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1)
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CustomTextField = (props) => {
  return <MuiTextField fullWidth {...fieldToTextField(props)} />;
};

export default function CustomizedDialogs(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose, open, user } = props;

  return (
    <Formik
      initialValues={{
        contractor: "",
        projectManager: "",
        name: ""
      }}
      validationSchema={Yup.object({
        contractor: Yup.string().required('Required'),
        projectManager: Yup.string().required('Required'),
        name: Yup.string().required('Required')
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          values.user = user.id;
          await projectService.createProject(values);
          enqueueSnackbar("Successfully create new project", { variant: 'success' });
          window.location.reload();
        } catch (error) {
          enqueueSnackbar(formError(error), { variant: 'error' });
        }
        setSubmitting(false);
        handleClose();
      }}
    >
      {({
        submitForm, isSubmitting, touched, errors
      }) => (
        <Dialog
          onClose={handleClose} aria-labelledby="new-project" open={open}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle id="new-project" onClose={handleClose}>
            CREATE NEW PROJECT
          </DialogTitle>
          <Divider/>
          <DialogContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box
                component={Form}
                display="flex"
                flexDirection="column"
                alignItems="center"
                flexWrap={"wrap"}
                width={400}
                mb={3}
              >
                {["name", "contractor", "projectManager"].map(value => {
                  const transformValue = value === "projectManager" ? "Project Manager" : value;
                  return (
                    <Box mt={1} key={value} width={"80%"}>
                      <Field
                        component={CustomTextField}
                        name={value}
                        label={capitalizeWord(transformValue)}
                      />
                    </Box>
                  )
                })}
              </Box>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <LocalSubmitButton
              submitForm={submitForm}
              isSubmitting={isSubmitting}
              btnText={"Submit"}
              startIcon={<AddCircleOutlineIcon/>}
            >
              Create
            </LocalSubmitButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
