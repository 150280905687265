import React from "react";
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const LocalSubmitButton = ({ isSubmitting, submitForm, btnText = 'Submit' }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      type={"submit"}
      size="small"
      disabled={isSubmitting}
      onClick={submitForm}
      style={{ float: 'right' }}
    >
      {
        isSubmitting ? <CircularProgress size={25}/> : btnText
      }
    </Button>
  )
}

export default LocalSubmitButton;
