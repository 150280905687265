import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useSnackbar } from 'notistack'
import { Formik } from 'formik';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress'
import Grow from '@material-ui/core/Grow'
import StructureInformation from './StructureInformation';
import Concrete from './ConcreteInformation';
import ConcreteStructureSchema from './ConcreteStructureSchema';
import { cementTypeService, concreteStructureService, concreteSupplierService } from '../../../../services'
import { convertTime, formError, pick } from '../../../../utils'
import cst from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Structure Information', 'Concrete Information'];
}

const Transition = React.forwardRef((props, ref) => {
  return <Grow timeout={3000} direction={"right"} ref={ref} {...props} />;
});

const NewConcreteStructure = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const {
    handleClose, open, projectId, userId
  } = props;
  const [concreteSupplier, setConcreteSupplier] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await concreteSupplierService.getConcreteSuppliers({
          createdBy: userId
        });
        setConcreteSupplier(response.results);
      } catch (e) {
        enqueueSnackbar(formError(e), { variant: 'error' })
      }
    })();
  }, []);

  const handleBack = () => {
    if (activeStep === 0) {
      handleClose();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        project: projectId,
        location: "",
        castingDate: "",
        concreteSupplier: undefined,
        cementType: undefined,
        property: {}
      }}
      validationSchema={ConcreteStructureSchema}
      onSubmit={async (values, { setSubmitting, errors }) => {
        console.log(errors);
        if (activeStep === 1) {
          try {
            let customCementType; let
              customConcreteSupplier
            if (values.concreteSupplier === cst.CREATE_VALUE) {
              customConcreteSupplier = await concreteSupplierService.createConcreteSupplier({
                name: values.concreteSupplierName,
                createdBy: userId
              })
            } else
              customConcreteSupplier = values.concreteSupplier
            if (values.concreteSupplier === cst.CREATE_VALUE || values.cementType === cst.CREATE_VALUE) {
              customCementType = await cementTypeService.createCementType(({
                name: values.concreteTypeName,
                concreteSupplier: customConcreteSupplier.id,
                property: values.property
              }))
            }
            const concreteStructureBody = {
              ...pick(values, [
                'castingDate', 'location', 'project'
              ]),
              cementType: customCementType ? customCementType.id : values.cementType.id
            }
            concreteStructureBody.castingDate = (new Date(concreteStructureBody.castingDate)).toString()
            await concreteStructureService.createConcreteStructure(concreteStructureBody);
            enqueueSnackbar('Successfully created new Concrete structure', { variant: 'success' });
            handleClose();
            window.location.reload()
          } catch (e) {
            enqueueSnackbar(formError(e), { variant: 'error' });
            console.log(e);
          }
        } else
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <Dialog
          onClose={handleClose}
          aria-labelledby="new-concrete-structure"
          open={open}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle id="new-concrete-structure" onClose={handleClose}>
            ADD NEW CONCRETE STRUCTURE
          </DialogTitle>
          <DialogContent
            TransitionComponent={Transition}
            className={classes.root}
          >
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === 0 && <StructureInformation {...formikProps}/>}
              {activeStep === 1
              && (
                <Concrete
                  {...formikProps}
                  concreteSupplier={concreteSupplier}
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            {formikProps.isSubmitting && <LinearProgress />}
            <br />
            <Box
              p={2}
              hidden={activeStep === 2}
            >
              <Button
                onClick={handleBack}
                className={classes.backButton}
              >
                {activeStep === 0 ? 'Cancel' : 'Back'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={formikProps.isSubmitting}
                onClick={formikProps.submitForm}
              >
                {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

const mapStateToProps = state => ({
  userId: state.auth.user.id
})

export default connect(mapStateToProps, undefined)(NewConcreteStructure)
