import { sensorService, concreteStructureService } from '../../../services';
import { avg, isAllZeroes, isObjectEqual } from '../../../utils';
import cst from '../../../constants';
import { MAIN_VIEW_STRENGTH } from './index';

const {
  LOWER_BOUND_SENSOR_TEMP,
  UPPER_BOUND_SENSOR_TEMP
} = require('../../../config');

export function isTemperatureValid(temp) {
  return LOWER_BOUND_SENSOR_TEMP <= temp && temp <= UPPER_BOUND_SENSOR_TEMP;
}

/**
 * Check if an instance of temperature is valid (datapoint1, datapoint2,...)
 * @param item
 * @param noOfDatapoint
 * @return {boolean} True if at least one data point is within the valid range
 */
function validTemp(item, noOfDatapoint, datapointIndexes) {
  for (const i of datapointIndexes) {
    const key = `datapoint${i}`;
    if (isTemperatureValid(item[key])) return true;
  }
  return false;
}

/**
 * Get average temperature, with consideration of valid range [MIN_TEMP, MAX_TEMP],
 * of an instance (datapoint1,...datapointn)
 * @param temp - transformed temperature ([[], [], []]
 * @param index - index of the instance
 * @param toFix
 * @return {number}
 */
export function getAvgTemp(temp, index, toFix = true) {
  let sum = 0;
  let noOfValidDatapoint = 0;
  for (let i = 0; i < temp.length; i++) {
    const temperature = temp[i][index];
    if (!isTemperatureValid(temperature)) continue;
    sum += temperature;
    noOfValidDatapoint += 1;
  }
  const result = sum / noOfValidDatapoint;
  return toFix ? parseFloat(result.toFixed(2)) : result;
}

export function getMaxTemp(temp, index) {
  let result;
  for (let i = 0; i < temp.length; i++) {
    const temperature = temp[i][index];
    if (!isTemperatureValid(temperature)) continue;
    if (result === undefined) result = temperature;
    else result = Math.max(result || 0, temperature);
  }
  return result;
}

/**
 * Generate datapoint indexes for a sensor
 * @param sensor
 * @return {Array|*[]}
 */
export function generateDatepointsIndex(sensor) {
  // If the sensor's data point indexes isn't set (Old data)
  if (!sensor.datapoints || !sensor.datapoints.length) {
    const result = [];
    for (let i = 0; i < sensor.noOfDatapoint; i++) result.push(i + 1);
    return result;
  }
  // Other wise, we must +1 to the original data point indexes
  return sensor.datapoints.map(datapointIndex => datapointIndex + 1);
}

const defaultSensorValue = {
  current: {
    strength: 0,
    temp: 0,
    maturity: 0,
    maxTemp: 0
  },
  property: []
};
/**
 * Transform sensors response from Dynamodb into graph-compatible parameter
 * @param {Object} sensorResponse - It has Items property which is the detailed datapoint of this sensor
 * @param {Object} concreteStructure
 * @param {Object} sensor - Given sensor's metadata
 * @return {{allLabels: Array, concreteStrength: Array, temperature: Array, allTemperature: Array, moisture: Array, time: Array, labelsMoisture: Array, labels: Array}|({allLabels, concreteStrength, temperature, allTemperature, time, labels}&{moisture: Array, labelsMoisture: number[]})}
 */
export const transformSensorData = (
  sensorResponse = { Items: [] },
  concreteStructure,
  sensor
) => {
  if (!sensorResponse.Items.length)
    return {
      concreteStrength: [], // Calc concrete strength of valid datapoints
      temperature: [], //  temperature of valid data points
      temperatureIndex: [], // index of valid temp data point
      allTemperature: [], // All temp
      labels: [], // Temp for valid data points
      allLabels: [], // All label
      labelsMoisture: [],
      moisture: [],
      time: [], // All time,
      sensor,
      ...defaultSensorValue
    };
  const tempData = sensorResponse.Items.filter(
    item => item.sensorType === cst.SENSOR_TYPE_TEMP
  ).map(item => {
    if (!sensor.datapoints || !sensor.datapoints.length) return item;
    const _item = { ...item };
    // Remove datapoints from item
    Object.keys(_item)
      .filter(key => key.includes('datapoint'))
      .forEach(key => {
        delete _item.key;
      });
    // Add datapoint which is in the sensor's datapointsIndex
    sensor.datapoints.forEach(datapointIndex => {
      _item[`datapoint${datapointIndex + 1}`] =
        item[`datapoint${datapointIndex + 1}`];
    });
    return _item;
  });

  const moistureData = sensorResponse.Items.filter(
    item => item.sensorType === cst.SENSOR_TYPE_MOISTURE
  );

  const datapointIndexs = generateDatepointsIndex(sensor);
  const temperatureIndex = [];
  const tempDataValid = tempData.filter((item, index) => {
    const testValid = validTemp(item, sensor.noOfDatapoint, datapointIndexs);
    if (testValid) temperatureIndex.push(index);
    return testValid;
  });

  // All temperature points, including outlier ones
  const allTemperature = [];
  datapointIndexs.forEach(index => {
    const arr = tempData.map(v => parseFloat(v[`datapoint${index}`]));
    allTemperature.push(arr);
  });

  // A valid Subset of allTemperature
  const temperature = [];
  datapointIndexs.forEach(index => {
    const arr = tempDataValid.map(v => parseFloat(v[`datapoint${index}`]));
    temperature.push(arr);
  });

  const params = concreteStructure.cementType.property;

  let currentMIndex = 0;
  let currentTime;
  let currentStrength;
  let currentTemp;

  const concreteStrength = tempDataValid
    .map((v, index) => {
      if (index === 0) {
        currentTime = v.time;
      } else {
        const deltaTime = (parseFloat(v.time) - currentTime) / 24 / 3600;
        currentTemp = getAvgTemp(temperature, index, false);
        currentTime = v.time;
        currentMIndex += sensorService.getDeltaMIndex({
          ...params,
          deltaTime,
          temp: currentTemp
        });
      }
      currentStrength = sensorService.getStrengthFromMIndex({
        ...params,
        Mt: currentMIndex
      });
      // return Math.max(currentStrength / concreteGrade * 100, 0);
      currentStrength = Math.max(currentStrength, 0);
      return currentStrength;
    })
    .map(v => parseFloat(v.toFixed(2)));

  // sensor property
  const property = [];
  for (let i = 0; i < sensor.noOfDatapoint; i++) {
    if (datapointIndexs.includes(i + 1)) {
      const tempArrayOfGivenDatapointIndex = temperature[
        datapointIndexs.findIndex(v => v === i + 1)
      ].filter(temp => isTemperatureValid(temp));
      const maxTemp = !tempArrayOfGivenDatapointIndex.length
        ? 0
        : Math.max(...tempArrayOfGivenDatapointIndex);
      property.push({ maxTemp });
    }
    // Default property value
    else property.push({});
  }

  for (let i = 0; i < datapointIndexs.length; i++) {
    temperature[i] = temperature[i].map(v => parseFloat(v.toFixed(2)));
  }

  const labels = tempDataValid
    .map(v => parseFloat(v.time) - sensor.activatedAt)
    .map(v => v / 3600 / 24);

  const allLabels = tempData
    .map(v => parseFloat(v.time) - sensor.activatedAt)
    .map(v => v / 3600 / 24);

  const tempResult = {
    concreteStrength,
    temperature,
    temperatureIndex,
    allTemperature,
    labels,
    allLabels,
    time: tempData.map(v => v.time),
    // Used to sync sensor's meta values
    current: {
      strength: currentStrength || 0,
      temp: currentTemp || 0,
      maturity: currentMIndex || 0,
      maxTemp: Math.max(...property.map(v => v.maxTemp || 0)) || 0
    },
    property,
    sensor
  };

  /* handle mositure data */
  const moisture = [];
  for (let i = 1; i <= sensor.noOfMoisture || 0; i++) {
    const arr = moistureData.map(v => parseFloat(v[`datapoint${i}`]));
    if (!isAllZeroes(arr)) moisture.push(arr);
  }
  const labelsMoisture = moistureData
    .map(v => parseFloat(v.time) - sensor.activatedAt)
    .map(v => v / 3600 / 24);

  return {
    ...tempResult,
    moisture,
    labelsMoisture
  };
};

export function getAverageSensorData(transformedSensorData) {
  let labels = new Set();
  transformedSensorData.forEach(sensor => {
    sensor.labels.forEach(v => {
      labels.add(v);
    });
  });
  let startIndexs = new Array(transformedSensorData.length).fill(0);
  const concreteStrength = [];
  const temperature = [];
  const time = [];

  // TODO: ASK XI here, don't be lazy
  labels = [...labels];
  labels.sort((a, b) => a - b);
  labels.forEach((label, index) => {
    let currentConcreteStrength = 0;
    let currentTemperature = 0;
    let currentTime = 0;
    let counter = 0;
    startIndexs.forEach((v, index) => {
      if (transformedSensorData[index].labels[v] <= label) {
        currentConcreteStrength +=
          transformedSensorData[index].concreteStrength[v];
        currentTemperature += getAvgTemp(
          transformedSensorData[index].temperature,
          v
        );
        currentTime += parseFloat(transformedSensorData[index].time[v]);
        counter += 1;
        startIndexs[index] += 1;
      }
    });
    concreteStrength.push(
      parseFloat((currentConcreteStrength / counter).toFixed(2))
    );
    temperature.push(parseFloat((currentTemperature / counter).toFixed(2)));
    time.push(currentTime / counter);
  });

  const resultTemp = {
    concreteStrength,
    // Here allTemp & allLabels are just alias for convenience of chart display
    allTemperature: temperature,
    allLabels: labels,
    labels,
    temperature,
    time
  };

  let labelsMoisture = new Set();
  transformedSensorData.forEach(sensor => {
    sensor.labelsMoisture.forEach(v => {
      labelsMoisture.add(v);
    });
  });
  startIndexs = new Array(transformedSensorData.length).fill(0);
  const moisture = [];

  // TODO: ASK XI here, don't be lazy
  labelsMoisture = [...labelsMoisture];
  labelsMoisture.forEach(label => {
    let currentMoisture = 0;
    let counter = 0;
    startIndexs.forEach((v, index) => {
      if (transformedSensorData[index].labelsMoisture[v] <= label) {
        currentMoisture += getAvgTemp(transformedSensorData[index].moisture, v);
        counter += 1;
        startIndexs[index] += 1;
      }
    });
    moisture.push(parseFloat((currentMoisture / counter).toFixed(2)));
  });

  return {
    ...resultTemp,
    moisture,
    labelsMoisture
  };
}

function isPropertyEqual(pr1, pr2) {
  if (pr1.length !== pr2.length) return false;
  let result = true;
  for (let i = 0; i < pr1.length; i++) {
    if (!isObjectEqual(pr1[i], pr2[i])) result = false;
  }
  return result;
}

export async function syncLocalMetaValueToRemote(
  transformedSensorData,
  concreteStructure,
  token
) {
  const promises = [];
  transformedSensorData.forEach(sensorData => {
    if (sensorData.current && sensorData.property) {
      // If local and remote data are sync, there is no need to upate
      console.log(sensorData.current, sensorData.sensor.current);
      if (
        isObjectEqual(sensorData.current, sensorData.sensor.current) &&
        isPropertyEqual(sensorData.property, sensorData.sensor.property)
      )
        return;
      console.log(sensorData.current, sensorData.sensor.current);
      promises.push(
        sensorService.updateSensor(sensorData.sensor.id, {
          current: sensorData.current,
          property: sensorData.property
        })
      );
    }
  });
  await Promise.all(promises);

  // update concrete structure's strength and max temp
  const strengths = transformedSensorData.map(s => {
    if (s.current) return s.current.strength || 0;
    return 0;
  });
  const temps = transformedSensorData
    .filter(s => s.current && s.current.maxTemp !== undefined)
    .map(s => s.current.maxTemp);

  const current = {
    strength: avg(strengths, 0),
    maxTemp: Math.max(...temps, 0)
  };
  // If remote and local data are equal, no need to sync anymore
  if (concreteStructure.current) {
    const { strength, maxTemp } = concreteStructure.current;
    if (strength === current.strength && maxTemp === current.maxTemp)
      return false;
  }
  console.log('UPDATE Concrete structure');
  return concreteStructureService.updateConcreteStructure(
    concreteStructure.id,
    {
      current
    },
    token
  );
}

export function isMainView(currentView) {
  return currentView === MAIN_VIEW_STRENGTH;
}
