import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import cst from '../../../../constants';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const Selector = props => {
  const classes = useStyles();
  const { selectedSensor, activeSensors, setSelectedSensor } = props;
  const handleChange = event => {
    const id = event.target.value;
    if (id === cst.SENSOR_AVERAGE) {
      setSelectedSensor({ id: cst.SENSOR_AVERAGE });
      return;
    }
    const correspondingSensor = activeSensors.find(ss => ss.id === id);
    setSelectedSensor(correspondingSensor);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={selectedSensor.id}
        onChange={handleChange}
        labelId={'sensor-selector'}
      >
        {<MenuItem value={cst.SENSOR_AVERAGE}>All</MenuItem>}
        {activeSensors.map(ss => (
          <MenuItem key={ss.id} value={ss.id}>
            {ss.device}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>Click to choose sensor</FormHelperText>
    </FormControl>
  );
};

export default Selector;
