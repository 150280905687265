import { handleResponse } from './helper';
import { URL } from '../config';

const getSensorData = async query => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(
    `${URL}/sensors/sensorData?${new URLSearchParams(query)}`,
    requestOptions
  ).then(handleResponse);
};

const getSensor = async id => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(`${URL}/sensors`, requestOptions).then(handleResponse);
};

const login = async credentials => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  };
  return fetch(`${URL}/auth/login`, requestOptions).then(handleResponse);
};

const register = async userInfo => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userInfo)
  };
  return fetch(`${URL}/auth/register`, requestOptions).then(handleResponse);
};

export default {
  getSensorData,
  login,
  register,
  getSensor
};
