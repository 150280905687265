import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box'
import {Card, CardContent, CardHeader, Typography} from '@material-ui/core';
import concreteSupplierService from '../../../services/concreteSupplier.service'

const useStyles = makeStyles({
  root: {
  },
  header: {
    marginRight: 'auto',
    padding: '5px 15px',
    border: '1px solid rgba(25, 118, 210, 0.5)'
  },
  headerCell: {
    align: 'right',
    // fontSize: '90%',
    whiteSpace: 'nowrap'
  },
  content: {
    fontSize: '18px',
    letterSpacing: 0.4,
    fontWeight: 400
  }
});

const ConcreteStructureOverview = (props) => {
  const classes = useStyles();
  const columns = [
    'Location',
    'Casting date time',
    'Day Since Casting',
    'Average Strength (MPa)',
    'Status',
    'Max temp (°C)',
    // 'Min temp (°C)',
    'Concrete Supplier',
    'Concrete Type',
    'Concrete Grade (MPa)',
  ]
  const {
    concreteStructure, concreteStructureOverview,
  } = props;
  const [concreteSupplier, setConcreteSupplier] = useState({})
  concreteStructureOverview[concreteStructureOverview.length - 3] = concreteSupplier.name

  useEffect(() => {
    (async () => {
      try {
        const concreteSupplierId = concreteStructure.cementType.concreteSupplier
        const response = await concreteSupplierService
            .getConcreteSupplier(concreteSupplierId)
        setConcreteSupplier(response)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  return (
      <Box className={classes.root}>
        <Card>
          <CardHeader
              title={<Typography variant={'h4'}>Key information</Typography>}
          />
          <CardContent className={classes.table} aria-label="overview">
            {concreteStructureOverview.map((v, index) => (
                <Box
                    key={v + index}
                    mb={2}
                >
                  <Typography
                      variant={'body2'}
                      color={'textSecondary'}
                      className={classes.content}
                  >
                    {columns[index]}
                  </Typography>
                  <Typography variant={'h6'} color={'textPrimary'} className={classes.content}>
                    {v}
                  </Typography>
                </Box>
            ))}
          </CardContent>
        </Card>
      </Box>
  );
}

export default ConcreteStructureOverview
