import cst from '../constants';

const initialState = { activatedAt: 0, deactivateAt: 0 };

export const sensor = (state = initialState, action) => {
  switch (action.type) {
    case cst.ACTION_ACTIVATE_SENSOR:
      return {
        ...state,
        activatedAt: action.payload
      };
    case cst.ACTION_DEACTIVATE_SENSOR:
      return {
        ...state,
        deactivatedAt: action.payload
      }
    default:
      return state
  }
};
