import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Cpu as CpuIcon
} from 'react-feather';
import CodeIcon from '@material-ui/icons/Code';
import NavItem from './NavItem';
import { appAction } from '../../../actions'
import cst from '../../../constants'


const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 200,
    boxShadow: 'rgba(0, 0, 0, 0.31) 0px 0px 1px 0px, rgba(0, 0, 0, 0.25) 0px 7px 12px -4px'
  },
  desktopDrawer: {
    boxShadow: 'rgba(0, 0, 0, 0.31) 0px 0px 1px 0px, rgba(0, 0, 0, 0.25) 0px 7px 12px -4px',
    width: 200,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ user, recentPageValue, onMobileClose, openMobile }) => {
  let items = [
    {
      href: '/app/projects',
      icon: ShoppingBagIcon,
      title: 'Projects'
    },
/*
    recentPageValue && recentPageValue[cst.RECENT_PAGE_VALUE_PROJECT] ? {
      href: `/app/projects/${recentPageValue[cst.RECENT_PAGE_VALUE_PROJECT]}`,
      icon: CpuIcon,
      title: 'Concrete Structures'
    } : undefined,
    {
      href: '/app/sensors',
      icon: CodeIcon,
      title: 'ConcreteInformation'
    },
    {
      href: '/app/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard'
    },
*/
    {
      href: '/app/account',
      icon: UserIcon,
      title: 'Account'
    },
    {
      href: '/app/settings',
      icon: SettingsIcon,
      title: 'Settings'
    },
    {
      href: '/login',
      icon: LockIcon,
      title: 'Login'
    },
    {
      href: '/register',
      icon: UserPlusIcon,
      title: 'Register'
    },
    {
      href: '/404',
      icon: AlertCircleIcon,
      title: 'Error'
    }
  ];
  items = items.filter(v => v !== undefined);
  
  const classes = useStyles();
  const location = useLocation();
  
  
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  
  if (!user)
    return null;
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      boxShadow={10}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="body1"
        >
          {user.firstName + ' ' + user.lastName}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.role}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
  
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

const mapStateToProps = state => ({
  user: state.auth.user,
  recentPageValue: state.app.recentPageValue
})

const mapDispatchToProps = dispatch => ({
  updatePageValue: (payload) => dispatch(appAction.updatePageValue(payload))
})
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
