import { handleResponse } from './helper'
import { URL } from '../config'

async function createCementType(cementTypeBody) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(cementTypeBody),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/cementTypes`, requestOptions)
    .then(handleResponse);
}

const getCementTypes = async (query) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/cementTypes?${new URLSearchParams(query)}`, requestOptions)
    .then(handleResponse);
};

const getCementType = async (id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/cementType/${id}`, requestOptions)
    .then(handleResponse);
};

const cementType = {
  createCementType,
  getCementTypes,
  getCementType
};

export default cementType;
