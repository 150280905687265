import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  colors,
  Divider,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import Selector from './component/Selector';
import { generateDatepointsIndex } from './helper';
import { formatDateTimeFromSecondUnixTimeOrDate } from '../../../utils';
import { MAIN_VIEW_DIFF, MAIN_VIEW_STRENGTH, MAIN_VIEW_TEMP } from './index';
import Statistics from './component/Statistics';
import Switcher from './component/Switcher';

const useStyles = makeStyles(theme => ({
  root: {},
  switchBn: {
    marginRight: theme.spacing(2)
  },
  cardContent: {
    paddingTop: 0
  }
}));

function genData(sensorData, index, color, selectedSensor) {
  const datapointsIndex = generateDatepointsIndex(selectedSensor);
  return datapointsIndex?.length > index
    ? {
        data: sensorData.allTemperature[index] || [],
        label: `Celcius degree (datapoint ${datapointsIndex[index]})`,
        borderColor: colors[color][500],
        backgroundColor: 'transparent'
      }
    : {};
}

const Temperature = ({
  sensorData = {},
  selectedSensor,
  activeSensors,
  setSelectedSensor,
  className,
  concreteStructure,
  mainView,
  setMainView,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const datasets =
    typeof sensorData.allTemperature[0] === 'number'
      ? [
          {
            data: sensorData.allTemperature || [],
            label: 'Celcius degree',
            borderColor: colors.deepPurple[500],
            backgroundColor: 'transparent'
          }
        ]
      : ['deepPurple', 'yellow', 'red', 'blue', 'green'].map((color, index) =>
          genData(sensorData, index, color, selectedSensor)
        );
  const data = {
    datasets,
    labels: sensorData.allLabels.map(v => v.toFixed(3)) || []
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.primary,
            autoSkip: true,
            maxTicksLimit: 5
          },
          gridLines: {
            display: false
          },
          scaleLabel: {
            display: true,
            labelString: 'Age (days)',
            fontSize: 12,
            color: theme.palette.text.primary
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.primary,
            beginAtZero: true,
            min: 0,
            stepSize: 20
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          scaleLabel: {
            display: true,
            labelString: 'Temperature (°C)',
            fontSize: 12,
            color: theme.palette.text.primary
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
      callbacks: {
        title(tooltipItem) {
          return `${tooltipItem[0].xLabel} days`;
        },
        afterTitle(tooltipItem) {
          return `${formatDateTimeFromSecondUnixTimeOrDate(
            sensorData.time[tooltipItem[0].index],
            false,
            true
          )}`;
        }
      }
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <>
            <Selector
              selectedSensor={selectedSensor}
              activeSensors={activeSensors}
              setSelectedSensor={setSelectedSensor}
            />
            {/* <ToCsv
              dataForChart={data}
            /> */}
          </>
        }
        title={
          <Box display={'flex'}>
            {mainView === MAIN_VIEW_TEMP && (
              <Switcher setMainView={setMainView} dest={MAIN_VIEW_DIFF} />
            )}
            <Typography variant={'h4'}>Temperature</Typography>
          </Box>
        }
      />
      <CardContent className={classes.cardContent}>
        {mainView === MAIN_VIEW_TEMP && (
          <Statistics
            concreteStructure={concreteStructure}
            selectedSensor={selectedSensor}
            sensorData={sensorData}
          />
        )}
        <Box height={210} mt={4} position="relative">
          <Line data={data} options={options} />
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

Temperature.propTypes = {
  className: PropTypes.string
};

export default Temperature;
