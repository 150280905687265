import { handleResponse } from './helper'
import { URL } from '../config';
import { avg, isValid, max } from '../utils'
import {defaultISensorFormulaParams} from '../constants'

const getSensorData = async (query: any) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/sensors/sensorData?${new URLSearchParams(query)}`, requestOptions)
    .then(handleResponse);
};

const getDeltaMIndex = (params: any) => {
  const {
    Q = defaultISensorFormulaParams.Q,
    RT = defaultISensorFormulaParams.RT, deltaTime = 0, temp = 0
  } = params
  return Math.exp(-Q * (1/(273 + temp) - 1/(273 + RT))) * deltaTime
}

const getStrengthFromMIndex = (params: any) => {
  const {
    Su = defaultISensorFormulaParams.Su,
    Offset = defaultISensorFormulaParams.Offset,
    M0 = defaultISensorFormulaParams.M0,
    K = defaultISensorFormulaParams.K,
    Mt = 0
  } = params
  return Su * ((K * (Mt - M0))/(1 + K *(Mt - M0))) - Offset
};


const getSensor = async (id: string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/sensors/${id}`, requestOptions)
    .then(handleResponse);
};

const getSensors = async (query = {}) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/sensors/?${new URLSearchParams(query)}`, requestOptions)
    .then(handleResponse);
};

const deleteSensor = async (id: string) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/sensors/${id}`, requestOptions)
    .then(handleResponse);
};

async function createSensor(sensorBody: any) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(sensorBody),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch(`${URL}/sensors`, requestOptions)
    .then(handleResponse);
}

const updateSensor = async (id: string, sensorBody: any) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sensorBody)
  };
  return fetch(`${URL}/sensors/${id}`, requestOptions)
    .then(handleResponse);
};

const aggregateProperty = (data: any) => {
  let strength = avg(
    data.map((o: any) => o.strength)
      .filter((o: any) => isValid(o))
  );
  const maxTemp = max(data.map((o: any) => o.maxTemp).filter((o: any) => isValid(o)));
  // const minTemp = min(data.map(o => o.minTemp).filter(o => isValid(o)));
  if (typeof strength === 'number')
    strength = Math.max(strength, 0);
  return {
    strength,
    maxTemp,
    //  minTemp
  }
}
const sensor = {
  getSensorData,
  getStrengthFromMIndex,
  getDeltaMIndex,
  updateSensor,
  getSensor,
  getSensors,
  deleteSensor,
  aggregateProperty,
  createSensor
};

export default sensor;
