import React, {useEffect, useState} from 'react'
import MUIDataTable from 'mui-datatables';
import Box from '@material-ui/core/Box';
import PerfectScrollBar from 'react-perfect-scrollbar'
import {makeStyles, Typography} from '@material-ui/core';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import {AddCircleOutline, TimerOff} from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import StopIcon from '@material-ui/icons/Stop';
import Grid from '@material-ui/core/Grid'
import {useSnackbar} from 'notistack'
import Chip from '@material-ui/core/Chip'
import {red} from '@material-ui/core/colors';
import NewSensor from './NewSensor'
import {getSensorDataForTable, isActive, isTerminated} from './helper'
import {sensorService} from '../../../services'
import {formError, getCurrentTime} from '../../../utils'
import Loader from '../../../components/Loader'
import CustomMuiDataTable from '../../../theme/CustomMuiDataTable';
import AlertDialog from '../../../components/Dialog'
import {redBtnTheme} from "../../../theme/customTheme";

const ALL_SENSOR_TERMINATE = 'ALL'

const useThemes = () => createMuiTheme({
  ...CustomMuiDataTable
})

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
  },
  productCard: {
    height: '100%'
  },
  upper: {

  },
  navBtn: {
    margin: '0 0 4px 8px'
  },
  chip: {
    minWidth: 86,
    textAlign: 'center',
    backgroundColor: '#ff9100', // theme.palette.secondary.main
    transform: 'scale(0.9)'
  }
}));

const CustomChip = (props) => {
  const classes = useStyles();
  return (
      <Chip
          className={classes.chip}
          {...props}
          color="secondary"
          size="small"
      />
  )
}
const columns = ['Device ID', 'Strength (MPa)', 'Max Temp (°C)',
  'Remarks', 'Last Receive Date'];
columns.push({
  name: 'Start/Terminate',
  options: {
    filter: false,
    sort: false
  }
});

const SensorListView = (props) => {
  const classes = useStyles();
  const { dispatchFetchConcreteStructure, concreteStructure } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useThemes();
  const [showNewSensor, setShowNewSensor] = useState(false);

  // states which are related to Delete sensor confirm
  const [openDialog, setOpenDialog] = React.useState(false);
  const [confirmDelete, setDeleteConfirm] = React.useState(false);
  const [rowsToDelete, setRowsToDelete] = useState({})

  // states which are related to terminate sensor confirm
  const [openTerminateDialog, setOpenTerminateDialog] = React.useState(false);
  const [terminateConfirm, setTerminateConfirm] = React.useState(false);
  const [sensorToTerminate, setSensorToTerminte] = React.useState(null);

  const terminateSensor = async (sensor) => {
    try {
      await sensorService.updateSensor(sensor.id, {
        deactivatedAt: getCurrentTime()
      })
      enqueueSnackbar(`Successfully Terminate sensor ${sensor.device}`, { variant: 'success' })
      // Todo: optimize here
      dispatchFetchConcreteStructure(1)
    } catch (e) {
      enqueueSnackbar(formError(e), { variant: 'error' })
    }
  }

  const handleTerminateAll = async () => {
    const promises = concreteStructure.sensor
        .filter(ss => isActive(ss))
        .map(ss => sensorService.updateSensor(ss.id, {
          deactivatedAt: getCurrentTime()
        }));
    try {
      if (!promises.length) {
        enqueueSnackbar('There is no active sensors!', { variant: 'info' });
        return;
      }
      const response = await Promise.all(promises)
      enqueueSnackbar(`Successful terminate ${response.length} sensors: ${response.map(ss => ss.device)}`, { variant: 'success' });
      // TODO: Optimize here, either by caching concrete structure's sensors or redux
      dispatchFetchConcreteStructure(1)
    } catch (e) {
      enqueueSnackbar(formError(e), { variant: 'error' });
    }
  }

  const handleShowNewSensor = () => {
    setShowNewSensor(true);
  };
  const handleHideNewSensor = () => {
    setShowNewSensor(false);
  };

  // Confirmation & delete sensors
  useEffect(() => {
    if (!confirmDelete || !rowsToDelete.data)
      return
    const sensorToDelete = rowsToDelete.data.map(d => concreteStructure.sensor[d.dataIndex]);
    const promises = sensorToDelete.map(sensor => sensorService.deleteSensor(sensor.id));
    (async () => {
      try {
        await Promise.all(promises);
        enqueueSnackbar(`Successfully delete ${sensorToDelete.length} sensor:
        ${sensorToDelete.map(sensor => sensor.device).join(', ')}`, { variant: 'success' });
        if (promises.length)
          dispatchFetchConcreteStructure(1)
      } catch (e) {
        enqueueSnackbar(formError(e), { variant: 'error' });
      } finally {
        // reset state
        setDeleteConfirm(false)
        setRowsToDelete({})
      }
    })()
  }, [confirmDelete, rowsToDelete])

  // Confirmation & terminate sensors
  useEffect(() => {
    if (!terminateConfirm || !sensorToTerminate)
      return
    (async () => {
      if (sensorToTerminate === ALL_SENSOR_TERMINATE)
        await handleTerminateAll()
      else
        await terminateSensor(sensorToTerminate)
      // Reset state
      setTerminateConfirm(false)
      setSensorToTerminte(null)
    })()
  }, [terminateConfirm, sensorToTerminate])

  if (!concreteStructure)
    return (
        <Loader/>
    )

  const sensorDataForTable = concreteStructure.sensor.map(
      v => getSensorDataForTable(v, concreteStructure.cementType.property.concreteGrade)
  );

  // MUI options
  const options = {
    tableBodyHeight: 'auto',
    onRowsDelete: (rowsDeleted) => {
      setRowsToDelete(rowsDeleted)
      setOpenDialog(true)
    }
  }

  columns[columns.length - 1].options.customBodyRenderLite = (dataIndex, rowIndex) => {
    const currentSensor = concreteStructure.sensor[rowIndex];

    if (isActive(currentSensor))
      return (
          <CustomChip
              icon={<StopIcon/>}
              label="Terminate"
              clickable
              /* onClick={() => deactivateSensor(currentSensor)} */
              onClick={() => {
                setSensorToTerminte(currentSensor)
                setOpenTerminateDialog(true)
              }}
          />
      )
    if (isTerminated(currentSensor))
      return (
          <CustomChip
              icon={<DoneIcon />}
              label="Completed"
              disabled
          />
      )

    return null;
  }

  return (
      <Grid container>
        <AlertDialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            setDeleteConfirm={setDeleteConfirm}
            content="This action will delete these sensors. Are you sure?"
            header="Confirm delete sensor(s)"
        />

        <AlertDialog
            openDialog={openTerminateDialog}
            setOpenDialog={setOpenTerminateDialog}
            setDeleteConfirm={setTerminateConfirm}
            content={'This action will terminate sensor(s). Are you sure?'}
            header="Confirm terminate sensor(s)"
        />
        <Grid item xs={12} component={Box} display="flex" justify="flex-end" >
          <MuiThemeProvider theme={redBtnTheme}>
            <Button
                color="primary"
                variant="contained"
                size="medium"
                className={classes.navBtn}
                startIcon={<TimerOff/>}
                onClick={() => {
                  setSensorToTerminte(ALL_SENSOR_TERMINATE)
                  setOpenTerminateDialog(true)
                }}
            >
              Terminate all
            </Button>
          </MuiThemeProvider>
          <Button
              color="primary"
              variant="contained"
              size="medium"
              className={classes.navBtn}
              startIcon={<AddCircleOutline/>}
              onClick={handleShowNewSensor}
          >
            Add sensor
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MuiThemeProvider theme={theme}>
            <PerfectScrollBar>
              <MUIDataTable
                  title={<Typography variant={'h4'}>Concrete Structures</Typography>}
                  data={sensorDataForTable}
                  columns={columns}
                  options={options}
              />
            </PerfectScrollBar>
          </MuiThemeProvider>
        </Grid>
        <NewSensor
            open={showNewSensor}
            handleClickOpen={handleShowNewSensor}
            concreteStructure={concreteStructure}
            handleClose={handleHideNewSensor}
        />
      </Grid>
  );
}

export default SensorListView
