import React, {useEffect, useState} from 'react'
import MUIDataTable from "mui-datatables";
import Box from '@material-ui/core/Box';
import PerfectScrollBar from 'react-perfect-scrollbar'

import {AddCircleOutline} from '@material-ui/icons';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Button from '@material-ui/core/Button';
import {useNavigate, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack'

import Tooltip from '@material-ui/core/Tooltip'
import Page from '../../../components/Page';
import NewConcreteStructure from './NewConcreteStructure'
import {concreteStructureService} from '../../../services'
import {getConcreteStructureOverview} from './helper'
import CustomMuiDataTable from '../../../theme/CustomMuiDataTable';
import {formError} from '../../../utils'
import IconBreadcrumbs from '../../../components/Breadcrumbs'
import AlertDialog from '../../../components/Dialog'

const useThemes = () => createMuiTheme({
  ...CustomMuiDataTable,
  MUIDataTableBodyCell: {
    root: {
      fontSize: '95%',
      '&:nth-child(10)': {
        cursor: 'pointer'
      }
    }
  }
})

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
  },
  productCard: {
    height: '100%'
  },
  sensorNav: {
    cursor: 'pointer'
  }
}));

const columns = ["Location", "Casting Date", "Day since casting", "Concrete Grade (MPa)",
  'Avg Strength (MPa)', "Curing status", 'Max Temp (°C)'];
columns.push({
  name: "View Sensor List",
  options: {
    filter: false,
    sort: false
  }
});

const ConcreteStructureListView = (props) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { enqueueSnackbar } = useSnackbar()
  const [openDialog, setOpenDialog] = React.useState(false);
  const [confirmDelete, setDeleteConfirm] = React.useState(false);
  const classes = useStyles();
  const { updateRecentPageParam } = props;
  const theme = useThemes();

  const [showNewSensor, setShowNewSensor] = useState(false);
  const [concreteStructure, setConcreteStructure] = useState([]);
  const [rowsToDelete, setRowsToDelete] = useState([])
  const [changeSignal, setChangeSignal] = useState(0);

  // Handle confirmation & delete concrete structure rows
  useEffect(() => {
    if (!confirmDelete)
      return
    const concreteStrToDelete = rowsToDelete.data.map(d => concreteStructure[d.dataIndex]);
    const promises = concreteStrToDelete
      .map(concreteStr => concreteStructureService.deleteConcreteStr(concreteStr.id));
    (async () => {
      try {
        await Promise.all(promises);
        enqueueSnackbar(`Successfully delete ${concreteStrToDelete.length} concrete structure:
        ${concreteStrToDelete.map(cs => cs.location).join(', ')}`, { variant: 'success' });
        setChangeSignal(changeSignal + 1);
      } catch (e) {
        enqueueSnackbar(formError(e), { variant: "error" });
        setChangeSignal(changeSignal + 1);
      }
    })()
  }, [confirmDelete])

  useEffect(() => {
    (async () => {
      const response = await concreteStructureService.getConcreteStructures({
        project: projectId,
        populate: 'sensor cementType project'
      })
      setConcreteStructure(response.results)
    })()
  }, [changeSignal])

  const handleClickOpen = () => {
    setShowNewSensor(true);
  };
  const handleClose = () => {
    setShowNewSensor(false);
  };

  const dataForListConcreteStructureTable = concreteStructure ?
      concreteStructure.map(v => getConcreteStructureOverview(v, true)) : [];

  columns[columns.length - 1].options.customBodyRender = (value, { rowIndex, tableState: {page, rowsPerPage }}) => {
    return (
      <Tooltip title="Click to view concrete structure detail">
        <ArrowRightAltIcon
          color="primary"
          className={classes.sensorNav}
          onClick={() => {
            navigate(`/app/dashboard/${concreteStructure[rowIndex + page * rowsPerPage].id}`, {
              state: {
                concreteStructure: concreteStructure[rowIndex],
                transformedCs: dataForListConcreteStructureTable[rowIndex]
              }
            })
          }}
        />
      </Tooltip>

    );
  }

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: 'standard',
    tableBodyHeight: '320px',
    rowsPerPageOptions: [5, 10, 15, 20],
    rowsPerPage: 10,
    onRowsDelete: (rowsDeleted) => {
      setRowsToDelete(rowsDeleted)
      setOpenDialog(true)
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {concreteStructure.length && (
      <IconBreadcrumbs
        path={[
          { link: `/app/projects/${concreteStructure[0].project.id}`, title: `${concreteStructure[0].project.name}` },
        ]}
      />
      )}
      <AlertDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setDeleteConfirm={setDeleteConfirm}
        content="This action will delete this concrete structure, along with all of its sensors. Are you sure?"
        header="Confirm delete concrete structure"
      />
      <Page
        className={classes.root}
        title="Concrete Structures"
      >
        <Box m={3} display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutline/>}
            size={"small"}
            onClick={handleClickOpen}
          >
            Add Concrete Structure
          </Button>
        </Box>
        <Box m={3}>
          <MuiThemeProvider theme={theme}>
            <PerfectScrollBar>
              <MUIDataTable
                title={"List of concrete structures"}
                data={dataForListConcreteStructureTable}
                columns={columns}
                options={options}
              />
            </PerfectScrollBar>
          </MuiThemeProvider>
        </Box>
        <NewConcreteStructure
          projectId={projectId}
          open={showNewSensor}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      </Page>
    </div>
  );
}

export default ConcreteStructureListView;
