import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { formatDateTimeFromSecondUnixTimeOrDate } from '../../../utils';
import Selector from './component/Selector';
import { MAIN_VIEW_STRENGTH, MAIN_VIEW_TEMP, MAIN_VIEW_DIFF } from './index';
import Switcher from './component/Switcher';
import Statistics from './component/Statistics';

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: 0
  },
  root: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  downloadIcon: {
    marginTop: theme.spacing(1)
  }
}));

const ConcreteStrength = ({
  className,
  sensor,
  selectedSensor,
  setSelectedSensor,
  activeSensors,
  mainView,
  setMainView,
  concreteStructure,
  sensorData = {},
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const getDataForChart = (toFix = 2) => ({
    datasets: [
      {
        data: sensorData.concreteStrength.map(v => Math.max(v, 0)) || [], // [0, 0.6, 0.7, 0.75, 0.78, 0.80, 0.81],
        label: 'MPa',
        borderColor: colors.green[500],
        backgroundColor: 'transparent'
      }
    ],
    labels: sensorData.labels.map(v => v.toFixed(toFix)) || []
  });

  const dataForChart = getDataForChart();
  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.primary,
            autoSkip: true,
            maxTicksLimit: 10
          },
          gridLines: {
            display: false
          },
          scaleLabel: {
            display: true,
            labelString: 'Age (days)',
            fontSize: 16,
            color: theme.palette.text.primary
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.primary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          scaleLabel: {
            display: true,
            labelString: 'Concrete Strength (MPa)',
            fontSize: 16,
            color: theme.palette.text.primary
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
      callbacks: {
        title(tooltipItem) {
          return `${tooltipItem[0].xLabel} days`;
        },
        afterTitle(tooltipItem) {
          return `${formatDateTimeFromSecondUnixTimeOrDate(
            sensorData.time[tooltipItem[0].index],
            false,
            true
          )}`;
        }
      }
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <>
            <Selector
              selectedSensor={selectedSensor}
              activeSensors={activeSensors}
              setSelectedSensor={setSelectedSensor}
            />
          </>
        }
        title={
          <Box display={'flex'}>
            {mainView === MAIN_VIEW_STRENGTH && (
              <Switcher setMainView={setMainView} dest={MAIN_VIEW_TEMP} />
            )}

            <Typography variant={'h4'}>Concrete Strength</Typography>
          </Box>
        }
      />
      <CardContent className={classes.content}>
        {mainView === MAIN_VIEW_STRENGTH && (
          <Statistics
            concreteStructure={concreteStructure}
            selectedSensor={selectedSensor}
            sensorData={sensorData}
          />
        )}
        <Box height={330} mt={4} position="relative">
          <Line data={dataForChart} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

ConcreteStrength.propTypes = {
  className: PropTypes.string
};

export default ConcreteStrength;
