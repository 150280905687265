import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useSnackbar } from 'notistack'
import { Formik } from 'formik';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress'
import Grow from '@material-ui/core/Grow'
import SensorInformation from './SensorInformation';
import SensorSchema from './SensorSchema';
import { sensorService } from '../../../../services'
import { formError, toSecond } from '../../../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Transition = React.forwardRef((props, ref) => {
  return <Grow direction="up" ref={ref} {...props} />;
});

export default function NewSensor(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose, open, concreteStructure } = props;

  return (
    <Formik
      initialValues={{
        remark: "",
        noOfDatapoint: 5,
        noOfMoisture: 5,
        datapoints: [],
        device: '',
        activatedAt: toSecond(concreteStructure.castingDate),
        concreteStructure: concreteStructure.id,
        project: typeof concreteStructure.project === 'object'
          ? concreteStructure.project.id : concreteStructure.project
      }}
      validationSchema={SensorSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const sensorBody = {
          ...values,
          remark: values.remark === '' ? undefined : values.remark
        }
        try {
          await sensorService.createSensor(sensorBody);
          enqueueSnackbar('Successfully created new sensor', { variant: 'success' });
          handleClose();
          window.location.reload()
        } catch (e) {
          enqueueSnackbar(formError(e), { variant: 'error' });
        }
        setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            ADD NEW SENSOR
          </DialogTitle>
          <DialogContent className={classes.root}>
            <SensorInformation {...formikProps}/>
          </DialogContent>
          <DialogActions>
            {formikProps.isSubmitting && <LinearProgress />}
            <br />
            <Box
              p={2}
            >
              <Button
                onClick={handleClose}
                className={classes.backButton}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={formikProps.isSubmitting}
                onClick={formikProps.submitForm}
              >
                Submit
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
