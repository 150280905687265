import { handleResponse } from './helper'
import { URL } from '../config';

const updateUserInfo = async ({ id, role, ...userInfo }) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userInfo)
  };
  return fetch(`${URL}/users/${id}`, requestOptions)
    .then(handleResponse);
};

export default {
  updateUserInfo
};
