import {createMuiTheme} from '@material-ui/core/styles';
import {red} from '@material-ui/core/colors';

export const redBtnTheme = createMuiTheme({
    palette: {
        primary: {
            main: red[500]
        },
    },
});
