import React from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {avg, getLastValue, max} from "../../../../utils";
import cst from "../../../../constants";
import {isTemperatureValid} from "../helper";
import {camelCaseToCapitalizeWord} from "../../../../utils/getInitials";

const TYPE_TEMP = 'temperature'
const TYPE_STRENGTH = 'concreteStrength'

const useStyles = makeStyles((theme) => ({
    statisticRoot: {
        height: 180
    },
    statisticTile: {
        fontWeight: 600,
    },
    statisticSubtitle: {
        fontSize: '1.2rem',
        lineHeight: 1.334
    }
}))
const RecentValue = ({
                         type, sensorData, concreteGrade, selectedSensor
                     }) => {
    const getRecentValue = (percentage = false, isMaxTemp = false) => {
        if (type === TYPE_TEMP) {
            if (selectedSensor.id === cst.SENSOR_AVERAGE) {
                if (isMaxTemp)
                    return max(sensorData[type])
                return getLastValue(sensorData[type])
            }
            let result;
            if (isMaxTemp) {
                result = selectedSensor.current.maxTemp || cst.NA_VALUE
            } else {
                const temperature = sensorData[type]
                    .map(arr => getLastValue(arr.filter(v => isTemperatureValid(v))))
                    .filter(v => v !== cst.NA_VALUE)
                    .map(v => parseFloat(v))
                result = avg(temperature);
            }
            return typeof result === 'number' ? result.toFixed(2) : result;
        }
        let lastValue = getLastValue(sensorData[type]);
        if (lastValue === cst.NA_VALUE) { return lastValue; }
        if (percentage)
            lastValue = (lastValue / concreteGrade * 100).toFixed(2)
        return lastValue;
    }

    const getUnit = (result, percentage) => {
        if (result === cst.NA_VALUE)
            return result;
        if (type === TYPE_TEMP)
            return `${result}  °C`;
        if (percentage)
            return `${result}  %`;
        return `${result}  MPa`;
    }

    const classes = useStyles();

    return (
        <Box
            boxShadow={20}
            className={classes.statisticRoot}
            p={2}
            mb={2}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'space-evenly'}
        >
            <Typography
                color={'secondary'}
                variant={'overline'}
                className={clsx(classes.statisticSubtitle)}
            >
                {type === TYPE_TEMP ? 'Max Temp: ' : ''}
                {getUnit(getRecentValue(true, type === TYPE_TEMP), true)}
            </Typography>

            <Typography
                variant={'h3'}
                color={'primary'}
                className={classes.statisticTile}
            >
                {getUnit(getRecentValue())}
            </Typography>
            <Typography
                variant={'overline'}
                color={'textSecondary'}
            >
                {`Latest ${camelCaseToCapitalizeWord(type)}`}
            </Typography>
        </Box>
    )
};

const Statistics = ({concreteStructure, sensorData, selectedSensor}) => {
    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                lg={6}
                xs={12}
            >
                <RecentValue
                    concreteGrade={concreteStructure.cementType.property.concreteGrade}
                    sensorData={sensorData}
                    type={TYPE_TEMP}
                    selectedSensor={selectedSensor}
                />
            </Grid>
            <Grid
                item
                lg={6}
                xs={12}
            >
                <RecentValue
                    sensorData={sensorData}
                    concreteGrade={concreteStructure.cementType.property.concreteGrade}
                    type={TYPE_STRENGTH}
                />
            </Grid>
        </Grid>
    )
}

export default Statistics;
