import React from 'react';
import { connect } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom';

function PrivateRoute({ component: Component, auth, ...props }) {
  const location = useLocation();
  props.location = location;
  const isExpired = () => {
    if (!auth || !auth.tokens || !auth.tokens.access.expires)
      return true;
    const now = new Date();
    const expireAt = new Date(auth.tokens.access.expires);
    if (expireAt - now < 0)
      return true;
    return false;
  }
  return (
    !isExpired() ? <Component {...props} />
      : <Navigate to="/login" state={location} />
  )
}

const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(mapStateToProps)(PrivateRoute)
