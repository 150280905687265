import cst from '../constants';
import { authService } from '../services'

function login(id) {
  return async dispatch => {
    try {
      const auth = await authService.login(id);
      return dispatch({
        type: cst.ACTION_LOGIN_SUCCESS,
        payload: auth
      })
    } catch (error) {
      throw error;
    }
  }
}

function logout() {
  return {
    type: cst.ACTION_LOGOUT
  }
}
export const authAction = {
  login,
  logout
}
