import cst from '../constants';

const initialState = { loading: false, recentPageValue: {} };

export const app = (state = initialState, action) => {
  switch (action.type) {
    case cst.ACTION_LOADING:
      return {
        ...state,
        loading: true
      };
    case cst.ACTION_LOADED:
      return {
        ...state,
        loading: false
      };
    case cst.UPDATE_RECENT_PAGE_VALUE:
      return {
        ...state,
        recentPageValue: {
          ...action.payload
        }
      }
    default:
      return state
  }
};
